<template>
    <div id="login" class="fullheight">
        <div class="hero-head">
            <div class="columns is-mobile is-marginless">
                <div class="column">
                    <a id="logo" href="https://fgs.quelimaging.com/" target="_blank">
                        <img src="@src/assets/QUEL_logo.svg" width=100px>
                        <!-- <QUELLogo class="w-96"/> -->
                    </a>
                </div>
            </div>
        </div>
        
        <div class="container">
            <div class="section"></div>
            <div class="columns is-mobile is-centered">
                <div class="column is-half">
                    <div class="notification login-box">
                        <div class="center is-size-4 column">
                            <h2>Log in</h2>
                        </div>
                        <div class="column space-y-5">
                            <div>
                                <div class="mb-1.5 font-semibold text-md">
                                    Email
                                </div>
                                <input type="email"
                                    class="w-full h-10 rounded-md pl-3 border focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
                                    placeholder="email"
                                    v-model="email"
                                    @keyup.enter="signIn()">
                            </div>

                            <!-- <b-field label="Password"> -->
                            <div>
                                <div class="mb-1.5 font-semibold text-md">
                                    Password
                                </div>
                                <div class="relative">
                                    <input :type="show ? 'password' : 'text'"
                                    class="w-full h-10 rounded-md pl-3 border focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
                                    placeholder="password"
                                    v-model="password"
                                    password-reveal
                                    @keyup.enter="signIn()">
                                    <div class="absolute inset-y-2 right-2 text-blue-500" @click="show = !show">
                                        <svg v-if="show" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                        </svg>
                                        <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                                
                            <!-- </b-field> -->
                        </div>
                        <div class="column is-marginless">
                            
                            <!-- <button class="button is-info" :class="{ 'is-loading': loading }" @click="signIn()">Login</button> -->
                            <!-- <router-link :to="{ name: 'home', params: { tab: tab.id } }" class="level-item level-right is-marginless" :class="{ 'is-loading': loading }" @click="singIn()">Login</router-link> -->
                            <router-link to="/" :class="{ 'is-loading': loading }">
                                <button class="button is-info" :class="{ 'is-loading': loading }" @click="signIn()">Login</button>
                            </router-link>
                            <p class="has-text-danger" v-if="errorMessage">{{ errorMessage }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns is-mobile is-centered">
                <div class="column is-half is-marginless is-paddingless">
                    <div class="level login-helper is-mobile">
                        <router-link to="/reset-password" class="level-item level-right is-marginless">Forgot Password</router-link>
                        <div class="horizontal-divider has-text-grey-light">|</div>
                        <div class="level-item level-left is-marginless">
                            <a class="mailtoui" href="mailto:info@quelimaging.com">
                                Contact Us
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { auth } from '@src/firebase';
const MailtoUI = require('../../node_modules/mailtoui/dist/mailtoui-min.js')

export default {
    title() {
        return `QUEL FGS`;
    },

    data() {
        return {
            auth,
            tabTitle: 'Login',
            newUser: false,
            email: '',
            password: '',
            errorMessage: '',
            loading: false,
            contactEmail: 'info@quelimaging.com',
            show: true,
        }
    },
    components: {
    },
    computed: {
        mailtoHref: function() {
            return 'mailto:' + this.contactEmail;
        }
    },

    methods: {
        async signIn() {
            this.loading = true;
            this.errorMessage = '';

            // *** create popup warning later on ***
            try {
                await auth.signInWithEmailAndPassword(this.email, this.password);
            } catch (error) {
                this.errorMessage = error.message;
            }

            this.loading = false;
        }
    },

    mounted() {
        MailtoUI.run();
    },

    props: {
        user: Object,
    }
}
</script>

<style>
    .is-horizontal-center {
        align-content: center;
    }
</style>