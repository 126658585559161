<template>
  <div id="app">

    <router-view></router-view>
  </div>

</template>

<script>

export default {

  name: 'App',

  components: {

  }
}

</script>

<style>
  #app {
    height: 100vh;
  }
</style>
