<script>
    import { Scatter } from 'vue-chartjs'
    import { ChartRegressions } from 'chartjs-plugin-regression'
    import watermark from '@images/QUEL_logo_watermark.png'

    export default {
        data () {
            return {
                useWatermark: true,
                chartOptions: Object,
                axisBounds: {
                    xMin: null,
                    yMin: null,
                    xMax: null,
                    yMax: null,
                },
                watermarkImg: null,
            }
        },
        created() {
            this.watermarkImg = new Image();
            this.watermarkImg.src = watermark;
        },
        mounted() {
            this.addPlugin({
                id: 'watermark',
                beforeDraw: () => {
                    if(this.useWatermark) {
                        let ctx = this.$refs.canvas.getContext("2d");
                        let watermarkSize = ctx.canvas.clientHeight/6.5;
                        ctx.drawImage(this.watermarkImg, 0, ctx.canvas.clientHeight*0.925, (watermarkSize/2)*2.67, watermarkSize/2)
                    }
                }
            })
        },
        methods: {
            getDatasetBounds() {
                // gets bound values for axes
                let offset = 0.1;
                let axis = 'x';
                for(let i = 0; i < 2; i++) {
                    // gets smallest values for bounds of dataset
                    let min = Infinity;
                        this.graphData.datasets[0].data.forEach((elem) => {
                        if (elem[axis] < min) {
                            min = elem[axis];
                        }
                    })
                    // this.axisBounds[`${axis}Min`] = Math.round((min - (min*offset))*1000)/1000;
                    this.axisBounds[`${axis}Min`] = (min - (min*offset)).toFixed(3);
                    

                    // gets largest values for bounds of dataset
                    let max = 0;
                    this.graphData.datasets[0].data.forEach((elem) => {
                        if (elem[axis] > max) {
                            max = elem[axis];
                        }
                    })
                    if(axis == 'y'){
                        this.axisBounds[`${axis}Max`] = Math.ceil((max + (max*offset)));
                    } else {
                        // this.axisBounds[`${axis}Max`] = Math.round((max + (max*offset))*1000)/1000;
                        this.axisBounds[`${axis}Max`] = (max + (max*offset)).toFixed(3);
                    }
                    axis = 'y';
                }
            },
            getChartOptions() {
                this.chartOptions = {
                    legend: {
                        display: false,                        
                    },
                    title: {
                        display: true,
                        text: this.chartSettings.title,
                        position: 'top',
                        fontSize: this.fontSize
                    },
                    tooltips: {
                        enabled: true,
                        mode: 'nearest',
                        bodyFontSize: (this.fontSize <= 14) ? this.fontSize : 20,
                        footerFontSize: (this.fontSize <= 14) ? this.fontSize-2 : 18,
                        callbacks: {
                            label: (tooltipItem, data) => {
                                let textBlock = [];
                                let label = data.datasets[tooltipItem.datasetIndex].label || '';          
                                if (label) {
                                    textBlock.push(label);
                                }
                                
                                return textBlock;
                            },
                            footer: (tooltipItem) => {
                                let textBlock = [];
                                let xLabels = this.chartSettings.xAxisLabel.split(' ');
                                let yLabels = this.chartSettings.yAxisLabel.split(' ');
                                textBlock.push(`${xLabels[0]}: ${Math.round(tooltipItem[0].xLabel * 1000) / 1000} ${xLabels[1].replace('(', '').replace(')', '')}`);
                                textBlock.push(`${yLabels[0]}: ${Math.round(tooltipItem[0].yLabel * 1000) / 1000} ${yLabels[1].replace('(', '').replace(')', '')}`);
                                return textBlock;
                            },
                            labelColor: (point) => {
                                return {
                                    borderColor: '',
                                    backgroundColor: this.graphData.datasets[point.datasetIndex].backgroundColor
                                };
                            },
                        },
                    },
                    scales: {
                        xAxes: [{
                            type: this.chartSettings.xAxisScale,
                            scaleLabel: {
                                display: true,
                                labelString: this.chartSettings.xAxisLabel,
                                fontSize: this.fontSize,
                                fontStyle: 'bold',
                            },
                            ticks: {
                                beginAtZero: false,
                                min: 0,
                                max: this.axisBounds.xMax,
                                autoSkip: true,
                                fontSize: this.fontSize,
                                // callback: changes scientific notation to regular
                                callback: function(...args) {
                                    const value = Chart.Ticks.formatters.linear.call(this, ...args);
                                    return Number(value).toString()
                                }
                            },
                            afterBuildTicks: (chart) => {
                                if(this.ticks.length > 0) {
                                    chart.ticks = this.ticks;
                                }
                            }
                        }],
                        yAxes: [{
                            type: this.chartSettings.yAxisScale,
                            scaleLabel: {
                                display: true,
                                labelString: this.chartSettings.yAxisLabel,
                                fontSize: this.fontSize,
                                fontStyle: 'bold',
                            },
                            ticks: {
                                beginAtZero: false,
                                min: 0,
                                max: this.axisBounds.yMax,
                                fontSize: this.fontSize,
                                autoSkip: true,
                        
                                // callback: changes scientific notation to regular
                                callback: function(...args) {
                                    const value = Chart.Ticks.formatters.logarithmic.call(this, ...args);
                                    if (value.length) {
                                        return Number(value).toLocaleString()
                                    }
                                    return value;
                                }
                            }
                        }],
                    },
                    responsive: this.responsive,
                    maintainAspectRatio: false,
                };
            }
        },
        extends: Scatter,
        props: {
            graphData: null,
            chartSettings: Object,
            ticks: Array,
            responsive: Boolean,
            fontSize: Number,
        },
        watch: {
            graphData: function() {
                if(this.graphData != null){
                    this.getDatasetBounds();
                    this.getChartOptions();
                    this.renderChart(this.graphData, this.chartOptions);
                    let regressionResults = {
                        graphData: this.$data._chart,
                        regressions: ChartRegressions,
                    }
                    this.$emit('onCompleteRegression', regressionResults);
                }
            },
            chartData () {
                this.$data._chart.update()
            },
            '$refs.canvas': {
                handler: () => {
                    console.log("something changed!");
                }
            }
        }
    }
</script>

<style>
</style>
