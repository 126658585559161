<template>
    <div>
        <div class="hero-head">
            <div class="columns is-mobile is-marginless">
                <div class="column">
                    <a id="logo" href="https://fgs.quelimaging.com/" target="_blank">
                        <img src="../assets/QUEL_logo.svg" width=100px>
                    </a>
                </div>
            </div>
        </div>
        
        <div class="container">
            <div class="section"></div>
            <div class="columns is-mobile is-centered">
                <div class="column is-half">
                    <div class="notification login-box">
                        <div v-if="expiredCode">
                            <div class="is-size-4 column">
                                <h2 class="has-text-weight-bold">Expired Link</h2>
                            </div>
                            <div class="column">
                                This url has expired. Please return to the home page to login or request another password reset email.
                            </div>
                            <div class="level">
                                <div class="level-item level-right">
                                    <button class="button is-info is-outlined" @click="returnToLogin()">Back to Login</button>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="validResetCode">
                                <div class="is-size-4 column">
                                    <h2 class="has-text-weight-bold">Reset Pasword</h2>
                                </div>
                                <div class="column">
                                    <b-field label="New Password">
                                        <b-input type="password"
                                            v-model="newPassword"
                                            password-reveal>
                                        </b-input>
                                    </b-field>
                                    <b-field label="Confirm New Password">
                                        <b-input type="password"
                                            v-model="confirmNewPassword"
                                            password-reveal>
                                        </b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <div class="level">
                                        <div class="level-item level-left">
                                            <button class="button is-info" @click="updatePassword()">Update Password</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="!emailSubmitted">
                                    <div class="is-size-4 column">
                                        <h2 class="has-text-weight-bold">Reset Pasword</h2>
                                    </div>
                                    <p class="column">
                                        Enter the email associated with your account and we'll send an email with instructions to reset your password.
                                    </p>
                                    <div class="column">
                                        <b-field label="Email">
                                            <b-input type="email"
                                                placeholder="email"
                                                v-model="email">
                                            </b-input>
                                        </b-field>
                                    </div>
                                    <div class="column is-marginless">
                                        <div class="level">
                                            <div class="level-item level-left">
                                                <button class="button is-info" @click="resetPassword()">Reset Password</button>
                                            </div>
                                            <div class="level-item level-right">
                                                <button class="button is-info is-outlined" @click="returnToLogin()">Back to Login</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="is-size-4 column">
                                        <h2 class="has-text-weight-bold">Email Sent</h2>
                                    </div>
                                    <p class="column">
                                        An email has been sent with instructions to reset your password. If you did not recieve an email, please try resubmitting your request.
                                    </p>
                                    <div class="level">
                                        <div class="level-item level-right">
                                            <button class="button is-info is-outlined" @click="returnToLogin()">Back to Login</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { auth } from '@src/firebase'

export default {
    data() {
        return {
            auth,
            emailSubmitted: false,
            email: '',
            validResetCode: false,
            expiredCode: false,
            newPassword: '',
            confirmNewPassword: '',
        }
    },

    computed: {
        oobCode: function () {
            return this.$route.query.oobCode;
        },

        apiKey: function () {
            return this.$route.query.apiKey;
        },
    }, 

    created: function() {
        if(this.oobCode == undefined || this.oobCode == '') {
            this.validResetCode = false;
        } else {
            auth.verifyPasswordResetCode(this.oobCode)
                .then(() => {
                    this.validResetCode = true;
                })
                .catch((error) => {
                    this.validResetCode = false;
                    console.log(error);
                    if(error.code == 'auth/invalid-action-code'){
                        this.expiredCode = true;
                    }
                })
        }
    },

    methods: {
        updatePassword() {
            if(!this.passwordsMatch()){
                this.passwordMismatchAlert();
            } else {
                auth.confirmPasswordReset(this.oobCode, this.newPassword)
                    .then(() => {
                        this.passwordUpdateSuccessAlert();
                    })
            }
        },

        passwordsMatch() {
            if(this.newPassword == this.confirmNewPassword) { return true; }
            return false;
        },

        resetPassword() {
            if(!this.emailFilled()){
                this.noEmailError();
            } else {
                auth.sendPasswordResetEmail(this.email).then(() => {
                }).catch((error) => {
                    console.log(error);
                });
                this.emailSubmitted = !this.emailSubmitted;
            }
        },

        returnToLogin() {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
        },
        
        emailFilled() {
            if(this.email != '') { return true; }
            return false;
        },

        passwordUpdateSuccessAlert() {
            this.$buefy.dialog.confirm({
                title: 'Success',
                message: "Your password has been successfully updated. You will be redirected to the home page to login.",
                confirmText: 'OK',
                onConfirm: () => this.returnToLogin()
            })
        },

        noEmailError() {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Please enter a valid email.',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
            })
        },

        passwordMismatchAlert() {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Passwords do not match.',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>