<template>
  <div>
    <ScatterChartRegTicks class="chart" :chartSettings="chartSettings" :ticks='concentrationTicks' :responsive="responsive" :fontSize="fontSize" :height="height" :width="width" :graphData="datacollection" @onCompleteRegression="onCompleteRegression"></ScatterChartRegTicks>
    <div v-if="type == 'tile'">
      <svg class="magnify h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
      </svg>
    </div>
  </div>
</template>

<script>
  import ChartRegressions from 'chartjs-plugin-regression'
  import ScatterChartRegTicks from '@appComponents/navbar_tabs/general_components/ScatterChartRegTicks.vue'

  export default {
    components: {
      ScatterChartRegTicks,
    },
    data () {
      return {
        num: 50,
        ChartRegressions,
        regressionResults: null,
        fontSize: null,
        datacollection: null,
        controlData: null,
        qWell: null,
        concentrationData: [],
        concentrationDataLabels: [],
        concentrationTicks: [],
        concentration: null,
        mean: null,
        chartSettings: {},
      }
    },
    mounted() {
      this.setOptions();
      this.setConcentrationData();
      this.fillData();
      this.setFont();
    },
    methods: {
      test() {
      },
      setOptions() {
        this.chartSettings = {
          title: 'Concentration Target',
          xAxisLabel: 'Concentration (nM)',
          yAxisLabel: 'Intensity (a.u.)',
          xAxisScale: 'logarithmic',
          yAxisScale: 'logarithmic',
        }
      },
      setConcentrationData() {
        let fluorophore = Object.values(this.graphData.Fluorophore);
        let mean = Object.values(this.graphData.Mean);
        let concentration = Object.values(this.graphData.Concentration);
        this.concentrationDataLabels = concentration;

        if(this.graphData.Version == 2) {
          for(let i = 0; i < mean.length; i++) {
            if(fluorophore[i] == 'IR125') {
              this.concentrationData.splice(0, 0, {
                x: concentration[i],
                y: mean[i],
              });
              this.concentrationTicks.splice(0, 0, concentration[i]);
            } else {
              this.controlData = {
                x: concentration[i],
                y: mean[i],
              };
            }
          }
        } else {
          for(let i = 0; i < mean.length; i++) {
            if(fluorophore[i] == 'IR125') {
              this.concentrationData.splice(0, 0, {
                x: concentration[i],
                y: mean[i],
              });
              this.concentrationTicks.splice(0, 0, concentration[i]);
            } else if(fluorophore[i] == 'QD800') {
              this.qWell = {
                x: concentration[i],
                y: mean[i],
              };
            } else {
              this.controlData = {
                x: concentration[i],
                y: mean[i],
              };
            }
          }
        }
        // starts ticks at 0
        this.concentrationTicks.splice(0, 0, 0);
      },
      setFont() {
        if(this.type == 'tile') {
          this.fontSize = 14;
        }
        else {
          this.fontSize = 22;
        }
      },
      fillData () {
          this.datacollection = {
              // labels: this.concentration,
              datasets: [
                {
                    label: 'Concentration Target',
                    data: this.concentrationData,
                    hoverBackgroundColor: '#2196f3',
                    backgroundColor: '#74bcf2',
                    pointRadius: 4,
                    pointHoverRadius: 8,
                    pointHitRadius: 10,
                    regressions: {
                      type: ['power'],
                      calculation: { precision: 10 },
                      line: { color: "#2196f3", width: 2 },
                      sections: [{startIndex: 1, endIndex: this.concentrationDataLabels[0]}]
                    },
                },
                {
                  label: 'Control Data',
                  data: [this.controlData],
                  hoverBackgroundColor: '#f6a770',
                  backgroundColor: '#f6a770',
                  pointRadius: 4,
                  pointHoverRadius: 8,
                  pointHitRadius: 10,
                },
                {
                  label: 'QD800',
                  data: [this.qWell],
                  hoverBackgroundColor: '#af0056',
                  backgroundColor: '#af0056',
                  pointRadius: this.graphData.Version == 2 ? 0 : 4,
                  pointHoverRadius: 8,
                  pointHitRadius: 10,
                }
              ]
          }
      },
      onCompleteRegression(chart) {
        if(this.type == 'tile') {
          this.regressionResults = chart.regressions.getSections(chart.graphData, 0);
          let regression = {
            type: 'concentration',
            results: this.regressionResults[0].result,
          }
          this.$emit('onCompleteRegression', regression);
        }
      }
    },
    watch: {
    },
    props: {
      graphData: Object,
      height: Number,
      width: Number,
      type: String,
      responsive: Boolean,
      version: Number,
    },
  }
</script>

<style lang="scss">
  .modal-content {
      overflow: visible,
  }
  .magnify {
      position: relative;
      height: 20px;
      float: right;
      &:hover {
        color: rgb(169, 169, 169);
      }
  }
</style>