<template>
  <div class="min-h-full">
      <h5 class="title is-5 has-text-black">Home</h5>
      <div class="columns mt-5 space-x-2">
          <div class="column notification h-48 m-5 isCentered hover:bg-blue-200 cursor-pointer" @click="changeTab('system_characterization')">
                <h5 class="w-full title text-center is-4 align-middle">
                    <b-icon class="align-middle mr-2"
                        icon="chart-timeline-variant"
                        size="is-medium">
                    </b-icon>
                    System Characterization
                </h5>
          </div>
          <div class="column notification h-48 m-5 isCentered hover:bg-blue-200 cursor-pointer" @click="changeTab('upload_images')">
                <h5 class="w-full title text-center is-4 align-middle">
                    <b-icon class="align-middle mr-2"
                        icon="upload"
                        size="is-medium">
                    </b-icon>
                    Upload Images
                </h5>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    methods: {
        changeTab(tab) {
            this.$emit('changeTab', tab);
        }
    }
}
</script>

<style>
    .isCentered {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
    }
</style>