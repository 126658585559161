<template>
    <div id='uploadDisplay' class="column notification rows">
        <!-- <div class="rows"> -->
            <div class="row">
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li><a class="has-text-black" href="#">{{ breadcrumbPath.device }}</a></li>
                        <li><a class="has-text-black" href="#">{{ breadcrumbPath.mode }}</a></li>
                    </ul>
                </nav>
            </div>
            <div class="row columns">
                <div id="image_set_name_field" class="column is-half">
                    <b-field label="Image Set Name" :type="{'is-danger': nameFieldEmpty }">
                        <template #label>
                            <div class="flex space-x-1">
                                <div>
                                    Image Set Name
                                </div>
                                <div class="has-tooltip">
                                    <div class='w-60 tooltip rounded shadow-lg p-4 bg-gray-200 text-black text-center font-semibold -mt-4 ml-5'>
                                        Name the image set to be uploaded. Given name will appear under Device / Mode on left-hand sidebar once uploaded
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                            </div>
                        </template>
                        <b-input 
                            v-model="imageSetName" 
                            maxlength=""
                            :disabled="uploadIsFinished"
                            icon-right="close-circle"
                            icon-right-clickable
                            @icon-right-click="clearIconClick"></b-input>
                    </b-field>
                </div>
            </div>

            <div v-if="files.length != 0">
                <section id="file_table" class="row">
                    <div class="columns">
                        <div class="column is-half">
                            <p class="has-text-weight-bold">File</p>
                        </div>
                        <div class="column is-one-third">
                            <p class="has-text-weight-bold">File Type</p>
                        </div>
                    </div>
                    <section class="section is-paddingless" v-bind:class="{ isUploading: isUploading, notUploading: notUploading }">
                        <div v-for="(file, index) in files" :key="file.name">
                            <File :file="files[index]" :fileIndex='index' :isUploading='isUploading' :uploadPath='uploadPath' :duplicateError='duplicateError[index]' @deleteFile='deleteFile(index)' @updateFileType='updateFileType(index, $event)' @uploadFinished='updateUploadFinished(index)'/>
                        </div>
                    </section>
                </section>
            </div>
            
            <section v-if="!isUploading" id="drag_drop" class="row">
                <b-field class="columns">
                    <b-upload class="column" v-model="files"
                        multiple
                        drag-drop
                        expanded
                        :disabled="uploadIsDisabled">
                        <!-- :accept="accepted_file_types" -->
                        <section class="section">
                            <div class="content has-text-centered has-text-grey">
                                <p>
                                    <b-icon
                                        icon="upload"
                                        size="is-large">
                                    </b-icon>
                                </p>
                                <p>Drop your files here or click to upload</p>
                            </div>
                        </section>
                    </b-upload>
                </b-field>
            </section>         
            <div class="row pad" v-if="!filesIsEmpty()">
                <div class="level">
                    <div class="level-item level-left">
                        <div v-if="uploadIsFinished">
                            <button class="button is-primary" @click="clearFiles">New Upload</button>
                        </div>
                        <div v-else>
                            <button class="button is-danger" @click="clearFiles">Cancel</button>
                        </div>
                    </div>
                    <div class="level-item level-right">
                        <button :disabled="uploadIsFinished" class="button is-primary" @click="sendFile">Upload</button>
                    </div>
                </div>
            </div>
        <!-- </div> -->
    </div>
</template>

<script>
import { db } from '@src/firebase';
import firebase from 'firebase/app'
import 'firebase/firestore'
import File from './upload_field_components/File'

const max_file_size = 52428800; // 50MB max per file
const max_file_count = 10;

export default {
    data() {
        return {
            accepted_file_types: "image/tiff,image/png,image/jpeg,text/plain",
            imageSetName: '',
            nameFieldEmpty: false,
            filesAdded: false,
            files: [],
            // options,
            duplicateError: [],
            isEmpty: false,
            isBordered: false,
            isStriped: false,
            isNarrowed: false,
            isHoverable: false,
            isFocusable: false,
            isLoading: false,
            hasMobileCards: true,
            stickyHeaders: false,
            labelPosition: 'on-border',
            isUploading: false,
            notUploading: true,
            uploadIsFinished: false,
            uploadIsDisabled: false,    // disables drag drop file upload
            uploadPath: {},
        }
    },
    components: {
        File
    },

    computed: {
        usersPath() {
            return db.collection('groups')
        }
    },

    methods: {
        clearIconClick() {
            this.imageSetName = '';
        },

        isFilled() {
            return false;
        },

        filesIsEmpty() {
            if(this.files.length == 0) { return true; }
            return false;
        },

        deleteFile(index) {
            this.files.splice(index, 1);
        },

        updateFileType(index, type) {
            this.files[index].fileType = type;
            this.duplicateCheck();
        },

        clearFiles() {
            this.files = [];
            this.uploadProgress = [];
            this.nameFieldEmpty = false;
            this.duplicateError = [];
            this.imageSetName = "";
            this.uploadIsFinished = false;
            this.isUploading = false;
        },

        duplicateCheck() {
            let typesSelected = {
                'Concentration Target': [],
                'Depth Target': [],
                'Resolution Target': []
            }

            for(let i = 0; i < this.duplicateError.length; i++) {
                this.$set(this.duplicateError, i, false);
            }

            this.files.forEach((file, index) => {
                for (const [key] of Object.entries(typesSelected)) {
                    if(file.fileType === key) { typesSelected[key].push(index) }
                }
            })

            for(const [, arr] of Object.entries(typesSelected)) {
                if(arr.length >= 2){
                    for(let i = 0; i < arr.length; i++) {
                        this.$set(this.duplicateError, arr[i], true);
                    }
                }
            }
        
        },

        updateUploadFinished(index) {
            this.files[index].uploadFinished = true;
            
            let allFinished = true;
            this.files.forEach((file) => {
                if(file.uploadFinished == false){ allFinished = false; }
            })
            if(allFinished) { 
                this.uploadIsFinished = true; 
                this.$emit('refreshMenu');
                this.imageUploadAlert();
            }
        },

        forEachPromise(elems, doc, deviceFolder, modeFolder, path, func) {
            return elems.reduce((promise, elem) => {
                return promise.then(() => {
                    return func(elem, doc, deviceFolder, modeFolder, path);
                });
            }, Promise.resolve());
        },

        async sendFile() {
            if(this.imageTypeNotSelected()){
                this.selectImageAlert();
            }
            else if(this.checkDuplicateError()) {
                this.duplicateAlert();
            }
            else if(this.emptyNameField()) {
                this.nameFieldAlert();
                this.nameFieldEmpty = true;
            }
            else if(this.breadcrumbPath.device == "" || this.breadcrumbPath.mode == "") {
                this.emptyPathAlert();
            }
            else if(this.groupID == undefined || this.groupID == 'null') {
                this.unknownErrorAlert();
            } else {
                let index = this.devices.findIndex(device => device.deviceName === this.breadcrumbPath.device);
                let deviceFolder = this.devices[index];
                let modeFolder = this.devices[index].modes.find(mode => mode.modeName === this.breadcrumbPath.mode);

                let path = db.collection('groups')
                            .doc(`${this.groupID}`)
                            .collection(`imageSets`)
                
                this.uploadPath = {
                    groupID: this.groupID,
                    deviceFolder: deviceFolder,
                    modeFolder: modeFolder,
                    path: path,
                }
                path.add({
                    createdOn: firebase.firestore.FieldValue.serverTimestamp(),
                    setName: this.imageSetName,
                    parentDevice: deviceFolder.deviceName,
                    parentMode: modeFolder.modeName,
                    verified: false,
                }).then((docRef) => {
                    this.uploadPath.docRef = docRef;
                    this.isUploading = true;
                });
            }
        },

        imageUploadAlert() {
            this.$buefy.dialog.alert({
                title: 'Upload Successful',
                message: "All files have been uploaded successfully",
                confirmText: 'OK'
            })
        },


        imageTypeNotSelected() {
            for(let i = 0; i < this.files.length; i++) {
                if(this.files[i].fileType === '') { 
                    return true;
                }
            }
            return false;
        },

        checkDuplicateError() {
            for(let i = 0; i < this.duplicateError.length; i++){
                if(this.duplicateError[i]) { return true }
            }
            return false;
        },

        unknownErrorAlert() {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: 'An unknown error has occured',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
            })
        },

        duplicateAlert() {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Duplicate image types found',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
            })
        },

        emptyNameField() {
            if(this.imageSetName) { return false }
            return true
        },

        nameFieldAlert() {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Enter name of image set',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
            })
        },

        selectImageAlert() {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Select image type for each image',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
            })
        },

        fileSizeAlert(filename) {
            this.$buefy.dialog.alert({
                title: `Max file size: ${max_file_size/1000000}MB`,
                message: `"${filename}" is too large`,
                confirmText: 'OK'
            })
        },

        emptyPathAlert() {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Select device and mode for uploading the image set',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
            })
        }
    },
    watch: {

        files: function() {
            const MAX_SIZE = max_file_size; 
            const MAX_FILE_COUNT = max_file_count;

            // remove files that exceed the maximum number of files
            if(this.files.length >= MAX_FILE_COUNT){
                while(this.files.length > MAX_FILE_COUNT){
                    this.files.splice(this.files.length-1, 1);
                }
                this.uploadIsDisabled = true;
            } else {
                this.uploadIsDisabled = false;
            }

            this.duplicateError = [];
            this.files.forEach((file, index) => {
                
                this.files[index].uploadFinished = false;
                this.duplicateError.push(false);
    
                // detect maximum file size
                if(file.size > MAX_SIZE) {
                    this.files.splice(index, 1);
                    this.fileSizeAlert(file.name);
                }
            });
        },
    },
    props: {
        breadcrumbPath: Object,
        devices: Array,
        groupID: String,
    }
}
</script>

<style lang="scss" scoped>
    #uploadDisplay {
        overflow: auto;
        padding-bottom: 1rem;
    }

    .notUploading {
        max-height: 200px;
        // height: 80%;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .isUploading {
        max-height: 400px;
    }
    #image_set_name_field {
        padding-top: 1rem;
        padding-bottom: 0px;
    }
    .notification.column {
        height: 700px;
    }
    .breadcrumb {
        a {
            text-decoration: none;
        }
    }
    .pad {
        padding-bottom: 1rem;
    }
    .tool-tip {
        font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
    }
</style>