<template>
    <div class="h-screen overflow-scroll">
        <h5 class="title is-5 has-text-black">Upload Images</h5>

        <div class="section"></div>
        <div id="uploader_window" class="columns is-centered overflow-scroll">
            <DeviceSidebar :class="{ notFullHeight: fullheight }" :fullheight="fullheight" @clicked="selectedTab" :devices="devices" v-on:deleteSet="deleteSet($event)" :activableImageSet='false'/>
            <div id="uploader_dragdrop" class="column is-half">
                <UploadField :breadcrumbPath="breadcrumbPath" :groupID="groupID" :devices="devices" v-on:refreshMenu="refreshMenu()" />
            </div>
        </div>
        <div class="h-1/2"></div>
    </div>
        
</template>

<script>
import DeviceSidebar from '@appComponents/navbar_tabs/general_components/DeviceSidebar'
import UploadField from '@appComponents/navbar_tabs/image_uploader/UploadField'

export default {
    data() {
        return {
            step: 'select_device',
            fullheight: true,
            breadcrumbPath: { device: "", mode: "", imageSet: "" }
        };
    },
    components: {
        DeviceSidebar,
        UploadField
    },
    methods: {
        selectedTab(value) {
            this.breadcrumbPath = value;
            this.$emit('selectedTab', this.breadcrumbPath);
        },

        refreshMenu() { 
            this.$emit('refreshMenu');
        },

        deleteSet(setToBeDeleted) {
            this.$emit('deleteSet', setToBeDeleted);
        }
    },

    props: {
        groupID: String,
        devices: Array
    }
}
</script>

<style lang="scss" scoped>
    #uploader_window{
        height: 700px;
    }
    #uploader_dragdrop {
        padding-top: 0px;
    }
</style>