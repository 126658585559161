<template>
    <div class="container columns max-h-screen no-scrollbar">
        <div v-if="hasImageSetSelected" class="container dataViewer">
            <div class="tile is-ancestor m-1 space-x-4 h-20">
                <div class="tile is-parent pl-0 pr-0">
                    <div class="flex m-auto title is-5 h-full w-full rounded-md bg-blue-900">
                        <p class="m-auto text-white" >{{ devices[this.breadcrumbPath.deviceIndex].modes[this.breadcrumbPath.modeIndex].imageSets[this.breadcrumbPath.imageSetIndex].imageSetName }}</p>
                    </div>
                </div>
                <div class="tile is-parent pl-0 pr-0">
                    <div class="flex m-auto title is-5 h-full w-full bg-white rounded-md">
                        <p class="m-auto">Fluorescence Image</p>
                    </div>
                </div>
                <div class="tile is-parent pl-0 pr-0">
                    <div class="flex m-auto title is-5 h-full w-full bg-white rounded-md">
                        <p class="m-auto">Data Analysis</p>
                    </div>
                </div>
                <div class="tile is-parent pl-0 pr-0">
                    <div class="flex m-auto title is-5 h-full w-full bg-white rounded-md">
                        <p class="m-auto">Metrics</p>
                    </div>
                </div>
            </div>
            <div v-if="hasConcentrationTarget" class="tile is-ancestor">
                <div class="tile is-parent pt-4">
                    <article class="tile is-child box p-2">
                        <div class="tile is-ancestor">
                            <!-- Concentration Target Title -->
                            <div class="m-1 rounded-l-md tile is-parent bg-gray-100">
                                <article class="tile is-child is-vertical-center has-text-centered titleTile">
                                    <div class="rows tile">
                                        <p class="row title is-4 is-text-center mb-3">Concentration Target</p>
                                        <figure class="row image h-28 w-28">
                                            <img src="@src/assets/images/QUEL_concentration_V2_top_sm.webp">
                                        </figure>
                                    </div>
                                </article>
                            </div>

                            <!-- Concentration Target Image -->
                            <div class="h-64 tile is-parent m-auto">
                                <div v-if="isLoadingConcentrationImages" class="flex m-auto">
                                    <div class="w-56 m-auto">
                                        <b-skeleton width="100" height="100" position="is-centered" active></b-skeleton>
                                    </div>
                                </div>
                                <div v-else class="m-auto">
                                    <div v-if="concentrationTargetImg != ''" class="m-auto">
                                        <b-tabs v-model="activeConcentrationImg" position="is-centered" :animated="false">
                                            <b-tab-item label="Raw">
                                                <article class="tile is-child box columns modalPrompt" @click="isConcentrationImageModalActive = true">
                                                    <figure class="h-32 w-32 flex">
                                                        <img :src="concentrationTargetImg" class="object-cover">
                                                    </figure>
                                                </article>
                                            </b-tab-item>

                                            <b-tab-item label="Log" v-if="concentrationTargetLogScaleImg != ''">
                                                <article class="tile is-child box columns modalPrompt" @click="isConcentrationImageModalActive = true">
                                                    <figure class="h-32 w-32 flex">
                                                        <img :src="concentrationTargetLogScaleImg" class="object-cover">
                                                    </figure>
                                                </article>
                                            </b-tab-item>
                                        </b-tabs>
                                    </div>
                                    <div v-else>
                                        <article class="tile is-child box">
                                            <figure class="h-32 w-32 flex">
                                                <img :src="concentrationTargetImg">
                                            </figure>
                                        </article>
                                    </div>
                                </div>
                                <b-modal v-if="concentrationTargetImg != ''" v-model="isConcentrationImageModalActive">
                                    <div class="flex h-5/6 w-auto bg-white p-5 my-16 mx-28 font-semibold">
                                        <!-- <img :src="concentrationTargetImg" class="m-auto"> -->
                                        <b-tabs v-model="activeConcentrationImg" position="is-centered" :animated="false" class="m-auto">
                                            <b-tab-item label="Raw">
                                                <img class="m-auto p-3" :src="concentrationTargetImg">
                                            </b-tab-item>

                                            <b-tab-item label="Log" v-if="concentrationTargetLogScaleImg != ''">
                                                <img class="m-auto p-3" :src="concentrationTargetLogScaleImg">
                                            </b-tab-item>
                                        </b-tabs>
                                    </div>
                                </b-modal>
                            </div>
                            
                            <!-- Concentration Target Graphs -->
                            <div v-if="dataIsVerified" class="tile is-parent">
                                <div v-if="isLoadingConcentrationGraph" class="flex m-auto">
                                    <b-skeleton width="200" height="160"></b-skeleton>
                                </div>
                                <div v-else>
                                    <div>
                                        <article v-if='hasConcentrationData' class="tile modalPrompt pt-4" @click="isConcentrationChartModalActive = !isConcentrationChartModalActive">
                                            <ConcentrationTargetChart class="ml-3" :graphData="concentrationData" :type="'tile'" :responsive="false" :width="tileWidth" :height="tileHeight" :version="concentrationVersion" @onCompleteRegression='onCompleteRegression'></ConcentrationTargetChart>
                                        </article>
                                        <div v-else class="tile has-text-weight-bold m-auto is-size-5">
                                            <b-loading :is-full-page="false" :v-model="true" :can-cancel="false"></b-loading>
                                        </div>
                                    </div>
                                    <b-modal v-if='hasConcentrationData' v-model="isConcentrationChartModalActive" :width="1280">
                                        <div class="columns h-full space-x-3">
                                            <div class="bg-white column rounded-md">
                                                <ChartLegend :type="'concentrationTarget'" :version="concentrationVersion"></ChartLegend>
                                            </div>
                                            <div class="bg-white column is-half rounded-md">
                                                <ConcentrationTargetChart class="pr-4 pl-3 pt-4" :graphData="concentrationData" :type="'modal'" :responsive="true" :height="modalHeight" :width='modalWidth' :version="concentrationVersion"></ConcentrationTargetChart>
                                            </div>
                                            <div class="bg-white column rounded-md">
                                                <b-table class="table" :data="concentrationTargetTable" :columns="concentrationColumns" :mobile-cards="false"></b-table>
                                            </div>
                                        </div>
                                    </b-modal>
                                </div>
                            </div>
                            <div v-else class="flex tile has-text-weight-bold m-auto is-size-5">
                                <p>Pending Verification</p>
                            </div>

                            <!-- Concentration Target Metrics -->
                            <div v-if="dataIsVerified" class="tile is-parent">
                                <div v-if="isLoadingConcentrationMetrics" class="flex-wrap mt-10 space-y-4">
                                    <div class="w-56 m-auto">
                                        <b-skeleton width="80%" active></b-skeleton>
                                        <b-skeleton width="80%" active></b-skeleton>
                                        <b-skeleton width="80%" active></b-skeleton>
                                    </div>
                                </div>
                                <div v-else>
                                    <article class="tile is-child box">
                                        <div class="metrics">
                                            <div class="is-size-5 mb-3" v-katex="concentrationRegression.equation"></div>
                                            <div class="is-size-5 mb-3" v-katex="concentrationRegression.r2"></div>
                                        </div>
                                        <div class="h-0.5 bg-gray-300"></div>
                                        <div class="">
                                            <div class="font-bold text-lg">
                                                Slope of fit:
                                            </div>
                                            <div class="pl-4 font-semibold">
                                                {{concentrationSlopeFit}}
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div v-else class="tile box m-1">
                            </div>
                        </div>
                    </article>
                </div>
            </div>
            <div v-if="hasDepthTarget" class="tile is-ancestor">
                <div class="tile is-parent pt-4">
                    <article class="tile is-child box p-2">
                        <div class="tile is-ancestor">
                            <!-- Depth Target Title -->
                            <div class="m-1 rounded-l-md tile is-parent bg-gray-100">
                                <article class="tile is-child is-vertical-center has-text-centered titleTile">
                                    <div class="rows tile">
                                        <p class="row title is-4 is-text-center mb-3">Depth Target</p>
                                        <figure class="row image h-28 w-28">
                                            <img src="@src/assets/images/QUEL_depth_V2_top_sm.webp">
                                        </figure>
                                    </div>
                                </article>
                            </div>
                            <!-- Depth Target Image -->
                            <div class="h-64 tile is-parent m-auto">
                                <div v-if="isLoadingDepthImages" class="flex m-auto">
                                    <div class="w-56 m-auto">
                                        <b-skeleton width="100" height="100" position="is-centered" active></b-skeleton>
                                    </div>
                                </div>
                                <div v-else class="m-auto">
                                    <div v-if="depthTargetImg != ''" class="flex m-auto">
                                        <b-tabs v-model="activeDepthImg" position="is-centered" :animated="false">
                                            <b-tab-item label="Raw">
                                                <article class="tile is-child box columns modalPrompt" @click="isDepthImageModalActive = true">
                                                    <figure class="h-32 w-32 flex">
                                                        <img :src="depthTargetImg" class="object-cover">
                                                    </figure>
                                                </article>
                                            </b-tab-item>

                                            <b-tab-item label="Log" v-if="depthTargetLogScaleImg != ''">
                                                <article class="tile is-child box columns modalPrompt" @click="isDepthImageModalActive = true">
                                                    <figure class="h-32 w-32 flex">
                                                        <img :src="depthTargetLogScaleImg" class="object-cover">
                                                    </figure>
                                                </article>
                                            </b-tab-item>
                                        </b-tabs>
                                    </div>
                                    <div v-else>
                                        <article class="tile is-child box">
                                            <figure class="h-32 w-32 flex">
                                            </figure>
                                        </article>
                                    </div>
                                </div>
                                <b-modal v-if="depthTargetImg != ''" v-model="isDepthImageModalActive">
                                    <div class="flex h-5/6 w-auto bg-white p-5 my-16 mx-28 font-semibold">
                                        <b-tabs v-model="activeDepthImg" position="is-centered" :animated="false" class="m-auto">
                                            <b-tab-item label="Raw">
                                                <img class="m-auto p-3" :src="depthTargetImg">
                                            </b-tab-item>

                                            <b-tab-item label="Log" v-if="depthTargetLogScaleImg != ''">
                                                <img class="m-auto p-3" :src="depthTargetLogScaleImg">
                                            </b-tab-item>
                                        </b-tabs>
                                    </div>
                                </b-modal>
                            </div>
                            <!-- Depth Target Graphs -->
                            <div v-if="dataIsVerified" class="tile is-parent">
                                <div v-if="isLoadingDepthGraph" class="flex m-auto">
                                    <b-skeleton width="200" height="160"></b-skeleton>
                                </div>
                                <div v-else>
                                    <div>
                                        <article v-if='hasDepthData' class="tile modalPrompt pt-4" @click="isDepthChartModalActive = !isDepthChartModalActive">
                                            <DepthTargetChart class="ml-3" :graphData="depthData" :type="'tile'" :responsive="false" :width="tileWidth" :height="tileHeight" :version="depthVersion" @onCompleteRegression="onCompleteRegression"></DepthTargetChart>
                                        </article>
                                        <div v-else>
                                            <b-loading :is-full-page="false" :v-model="true" :can-cancel="false"></b-loading>
                                        </div>
                                    </div>
                                    <b-modal v-if='hasDepthData' v-model="isDepthChartModalActive" :width="1280">
                                        <div class="columns h-full space-x-3">
                                            <div class="bg-white column rounded-md is-one-fourth">
                                                <ChartLegend :type="'depthTarget'"></ChartLegend>
                                            </div>
                                            <div class="bg-white column is-half rounded-md">
                                                <DepthTargetChart class="pr-4 pl-3 pt-4" :graphData="depthData" :type="'modal'" :responsive="true" :height="modalHeight" :width='modalWidth' :version="depthVersion"></DepthTargetChart>
                                            </div>
                                            <div class="bg-white column rounded-md is-one-fourth">
                                                <b-table class="table" :data="depthTargetTable" :columns="depthColumns" :mobile-cards="false" :sticky-header="true" :height='520'></b-table>
                                            </div>
                                        </div>
                                    </b-modal>

                                </div>
                            </div>
                            <div v-else class="flex tile has-text-weight-bold m-auto is-size-5">
                                <p>Pending Verification</p>
                            </div>

                            <!-- Depth Target Metrics -->
                            <div v-if="dataIsVerified" class="tile is-parent">
                                <div v-if="isLoadingDepthMetrics" class="flex-wrap mt-10 space-y-4">
                                    <div class="w-56 m-auto">
                                        <b-skeleton width="80%" active></b-skeleton>
                                        <b-skeleton width="80%" active></b-skeleton>
                                        <b-skeleton width="80%" active></b-skeleton>
                                    </div>
                                </div>
                                <div v-else>
                                    <article class="tile is-child box">
                                        <div class="metrics">
                                            <div class="is-size-5 mb-3" v-katex="depthRegression.equation"></div>
                                            <div class="is-size-5 mb-3" v-katex="depthRegression.r2"></div>
                                        </div>
                                        <div class="h-0.5 bg-gray-300"></div>
                                        <div class="">
                                            <div class="font-bold text-lg">
                                                CNR of 3:
                                            </div>
                                            <div class="pl-4 font-semibold">
                                                {{depthCNRAt3}}
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div v-else class="tile box m-1">
                            </div>
                        </div>
                    </article>
                </div>
            </div>
            <div v-if="hasResolutionTarget" class="tile is-ancestor">
                <div class="tile is-parent pt-4">
                    <article class="tile is-child box p-2">
                        <div class="tile is-ancestor">
                            <!-- Resolution Target Title -->
                            <div class="m-1 rounded-l-md tile is-parent bg-gray-100">
                                <article class="tile is-child is-vertical-center has-text-centered titleTile">
                                    <div class="rows tile">
                                        <p class="row title is-4 is-text-center mb-3">Resolution Target</p>
                                        <figure class="row image h-28 w-28">
                                            <img src="@src/assets/images/QUEL_resolution_V2_top_sm.webp">
                                        </figure>
                                    </div>
                                </article>
                            </div>

                            <!-- Resolution Target Image Set -->
                            <div class="h-64 tile is-parent m-auto">
                                <div v-if="isLoadingResolutionImages" class="flex m-auto">
                                    <div class="w-56 m-auto">
                                        <b-skeleton width="100" height="100" position="is-centered" active></b-skeleton>
                                    </div>
                                </div>
                                <div v-else class="m-auto">
                                    <div v-if="resolutionTargetImg != ''" class="m-auto">
                                        <b-tabs v-model="activeResolutionImg" position="is-centered" :animated="false">
                                            <b-tab-item label="Raw">
                                                <article class="tile is-child box columns modalPrompt" @click="isResolutionImageModalActive = true">
                                                    <figure class="h-32 w-32 flex">
                                                        <img :src="resolutionTargetImg" class="object-cover">
                                                    </figure>
                                                </article>
                                            </b-tab-item>

                                            <b-tab-item label="Log" v-if="resolutionTargetLogScaleImg != ''">
                                                <article class="tile is-child box columns modalPrompt" @click="isResolutionImageModalActive = true">
                                                    <figure class="h-32 w-32 flex">
                                                        <img :src="resolutionTargetLogScaleImg" class="object-cover">
                                                    </figure>
                                                </article>
                                            </b-tab-item>
                                        </b-tabs>
                                    </div>
                                    <div v-else>
                                        <article class="tile is-child box">
                                            <figure class="h-32 w-32 flex">
                                                <img :src="resolutionTargetImg">
                                            </figure>
                                        </article>
                                    </div>
                                </div>
                                <b-modal v-if="resolutionTargetImg != ''" v-model="isResolutionImageModalActive">
                                    <div class="flex h-5/6 w-auto bg-white p-5 my-16 mx-28 font-semibold">
                                        <!-- <img :src="resolutionTargetImg" class="m-auto"> -->
                                        <b-tabs v-model="activeResolutionImg" position="is-centered" :animated="false" class="m-auto">
                                            <b-tab-item label="Raw">
                                                <img class="m-auto p-3" :src="resolutionTargetImg">
                                            </b-tab-item>

                                            <b-tab-item label="Log" v-if="resolutionTargetLogScaleImg != ''">
                                                <img class="m-auto p-3" :src="resolutionTargetLogScaleImg">
                                            </b-tab-item>
                                        </b-tabs>
                                    </div>
                                </b-modal>
                            </div>

                            <!-- Resolution Target Graphs -->
                            <div v-if="dataIsVerified"  class="tile is-parent">
                                <div v-if="isLoadingResolutionGraph" class="flex m-auto">
                                    <b-skeleton width="200" height="160"></b-skeleton>
                                </div>
                                <div v-else>
                                    <div>
                                        <article v-if='hasResolutionData' class="tile modalPrompt pt-4" @click="isResolutionChartModalActive = !isResolutionChartModalActive">
                                            <ResolutionTargetChart class="ml-3" :graphData="resolutionData" :type="'tile'" :responsive="false" :width="tileWidth" :height="tileHeight"></ResolutionTargetChart>
                                        </article>
                                        <div v-else class="tile has-text-weight-bold m-auto is-size-5">
                                            <b-loading :is-full-page="false" :v-model="true" :can-cancel="false"></b-loading>
                                        </div>
                                    </div>
                                    <b-modal v-if='hasResolutionData' v-model="isResolutionChartModalActive" :width="1280">
                                        <div class="columns h-10/12 space-x-4">
                                            <div class="bg-white column rounded-md is-one-fifth">
                                                <ChartLegend :type="'resolutionTarget'"></ChartLegend>
                                            </div>
                                            <div class="bg-white column is-half rounded-md">
                                                <ResolutionTargetChart class="pr-4 pl-3 pt-4" :graphData="resolutionData" :type="'modal'" :responsive="true" :height="modalHeight" :width='modalWidth'></ResolutionTargetChart>
                                            </div>
                                            <div class="bg-white column rounded-md">
                                                <b-table class="table" :data="resolutionTargetTable" :columns="resolutionColumns" :mobile-cards="false" :sticky-header="true" :height='520'></b-table>
                                            </div>
                                        </div>
                                    </b-modal>
                                </div>
                            </div>
                            <div v-else class="flex tile has-text-weight-bold m-auto is-size-5">
                                <p>Pending Verification</p>
                            </div>

                            <!-- Resolution Target Metrics PLACEHOLDER -->
                            <div v-if="dataIsVerified" class="tile is-parent">
                                <!-- <div v-if="isLoadingResolutionMetrics" class="flex-wrap mt-10 space-y-4">
                                    <div class="w-56 m-auto">
                                        <b-skeleton width="80%" active></b-skeleton>
                                        <b-skeleton width="80%" active></b-skeleton>
                                        <b-skeleton width="80%" active></b-skeleton>
                                    </div>
                                </div>
                                <div v-else>
                                    <article class="tile is-child box">
                                        <div class="metrics">
                                            <div class="is-size-5 mb-3" v-katex="resolutionRegression.equation"></div>
                                            <div class="is-size-5 mb-3" v-katex="resolutionRegression.r2"></div>
                                        </div>
                                    </article>
                                </div> -->
                                <div>
                                    <article class="tile is-child box">
                                        <div class="metrics">
                                            <div class="is-size-4 mb-3" v-katex="resolutionRegression.equation"></div>
                                            <div class="is-size-4 mb-3" v-katex="resolutionRegression.r2"></div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div v-else class="tile box m-1">
                            </div>
                        </div>
                    </article>
                </div>
            </div>
            <div class="h-1/2 w-full"></div>
        </div>
        <div v-else class="column columns is-centered is-vcentered">
            <b-notification class="column is-centered is-5 is-size-4 is-prompt" aria-close-label="Close notification">
                Select an image set from the menu on the left that you would like to view.
            </b-notification>
        </div>
    </div>
</template>

<script>
import { db, storage } from '@src/firebase'
import ConcentrationTargetChart from './charts/ConcentrationTargetChart'
import DepthTargetChart from './charts/DepthTargetChart'
import ResolutionTargetChart from './charts/ResolutionTargetChart'
import ChartLegend from './charts/ChartLegend'
import regression from 'regression';

export default {
    data() {
        return {       
            db,
            storage,     
            isLoading: false,
            dataIsVerified: false,
            hasImageSetSelected: false,
            // tile and modal data
            tileHeight: 200,
            tileWidth: 250,
            modalHeight: 500,
            modalWidth: 600,
            // concentration target
            isLoadingConcentrationImages: true,
            isLoadingConcentrationGraph: true,
            isLoadingConcentrationMetrics: true,
            activeConcentrationImg: 0,
            hasConcentrationTarget: false,
            hasConcentrationData: false,
            concentrationData: {},
            concentrationVersion: 1,
            concentrationTargetImg: '',
            concentrationTargetLogScaleImg: '',
            concentrationRegression: {
                equation: '',
                r2: '',
            },
            concentrationSlopeFit: null,
            isConcentrationImageModalActive: false,
            isConcentrationChartModalActive: false,
            // depth target
            isLoadingDepthImages: true,
            isLoadingDepthGraph: true,
            isLoadingDepthMetrics: true,
            activeDepthImg: 0,
            hasDepthTarget: false,
            hasDepthData: false,
            depthData: {},
            depthVersion: 1,
            depthTargetImg: '',
            depthTargetLogScaleImg: '',
            depthRegression: {
                equation: '',
                r2: '',
            },
            depthCNRAt3: null,
            isDepthImageModalActive: false,
            isDepthChartModalActive: false,
            // resolution target
            isLoadingResolutionImages: true,
            isLoadingResolutionGraph: true,
            isLoadingResolutionMetrics: true,
            activeResolutionImg: 0,
            hasResolutionTarget: false,
            hasResolutionData: false,
            resolutionData: {},
            resolutionTargetImg: '',
            resolutionTargetLogScaleImg: '',
            isResolutionImageModalActive: false,
            isResolutionChartModalActive: false,
            resolutionRegression: {
                equation: '',
                r2: '',
            },
            //
            storedImages: {},
            isQueryingImages: false,
            def: null,
            concentrationTargetTable: [],
            concentrationColumns: [
                {
                    field: 'roi_label',
                    label: 'Concentration (nM)',
                    numeric: true
                },
                {
                    field: 'mean',
                    label: 'Mean (a.u.)',
                    numeric: true
                },
                {
                    field: 'std_deviation',
                    label: 'Standard Deviation',
                    numeric: true
                },
            ],
            depthTargetTable: [],
            depthColumns: [
                {
                    field: 'roi_label',
                    label: 'Depth (mm)',
                    numeric: true
                },
                {
                    field: 'mean',
                    label: 'Mean (a.u.)',
                    numeric: true
                },
                {
                    field: 'std_deviation',
                    label: 'Standard Deviation',
                    numeric: true
                },
                {
                    field: 'cnr',
                    label: 'CNR',
                    numeric: false
                },
            ],
            resolutionTargetTable: [],
            resolutionColumns: [
                {
                    field: 'resolution',
                    label: 'Resolution (lp/mm)',
                    numeric: true
                },
                {
                    field: 'contrastMean',
                    label: 'Contrast (%)',
                    numeric: true
                },
                {
                    field: 'contrastStd',
                    label: 'Contrast Std Error',
                    numeric: true,
                }
            ]
        }
    },
    components: {
        ConcentrationTargetChart,
        DepthTargetChart,
        ResolutionTargetChart,
        ChartLegend,
    },

    created() {
        // console.log(this.devices)
        this.version = this.graphData.Version;
    },
    methods: {
        clearValues() {
            // reset loading graphics
            this.isLoadingConcentrationImages = true;
            this.isLoadingConcentrationGraph = true;
            this.isLoadingConcentrationMetrics = true;
            this.isLoadingDepthImages = true;
            this.isLoadingDepthGraph = true;
            this.isLoadingDepthMetrics = true;
            this.isLoadingResolutionImages = true;
            this.isLoadingResolutionGraph = true;
            this.isLoadingResolutionMetrics = true;
            // graph
            this.concentrationData =  {};
            this.depthData =  {};
            this.resolutionData =  {};
            // images
            this.concentrationTargetImg =  '';
            this.concentrationTargetLogScaleImg =  '';
            this.depthTargetImg =  '';
            this.depthTargetLogScaleImg =  '';
            this.resolutionTargetImg =  '';
            this.resolutionTargetLogScaleImg =  '';
            // reset regression data
            this.$set(this.concentrationRegression, 'equation', '');
            this.$set(this.concentrationRegression, 'r2', '');
            this.$set(this.depthRegression, 'equation', '');
            this.$set(this.depthRegression, 'r2', '');
            // reset tabs
            this.activeConcentrationImg = 0;
            this.activeDepthImg = 0;
            this.activeResolutionImg = 0;
        },
        assignURL(ref) {
            this.hasImageSetSelected = true;
            //
            this.hasConcentrationTarget = false;
            this.hasDepthTarget = false;
            this.hasResolutionTarget = false;
            //
            this.hasConcentrationData = false;
            this.hasDepthData = false;
            this.hasResolutionData = false;
            this.clearValues();
            // for each file in firestore
            ref.forEach((file) => {
                // concentration target images
                if (file.type == 'concentration target') {
                    this.concentrationTargetImg = file.url;
                    if(file.logScaleURL != undefined) {
                        this.concentrationTargetLogScaleImg = file.logScaleURL;
                    }
                    this.isLoadingConcentrationImages = false;
                }
                // depth target image
                else if (file.type == 'depth target') {
                    this.depthTargetImg = file.url;
                    if(file.logScaleURL != undefined) {
                        this.depthTargetLogScaleImg = file.logScaleURL;
                    }
                    this.isLoadingDepthImages = false;
                } 
                else if (file.type == 'resolution target') {
                // resolution target image
                    this.resolutionTargetImg = file.url;
                    if(file.logScaleURL != undefined) {
                        this.resolutionTargetLogScaleImg = file.logScaleURL;
                    }
                    this.isLoadingResolutionImages = false;
                }
                else if (file.type == 'concentration data') {
                // concentration graph data
                    try {
                        fetch(file.url, {
                                mode: 'cors', // no-cors, *cors, same-origin
                            })
                            .then(response => response.json())
                            .then(data => {
                                this.concentrationData = data;
                                this.fillTable('concentration');
                                this.hasConcentrationData = true;
                                this.isLoadingConcentrationGraph = false;
                            })
                    } catch {
                        this.hasConcentrationData = false;
                    }
                }
                else if (file.type == 'depth data') {
                    // depth graph data
                    try {
                        fetch(file.url, {
                                mode: 'cors', // no-cors, *cors, same-origin
                            })
                            .then(response => response.json())
                            .then(data => {
                                this.depthData = data;
                                this.fillTable('depth');
                                this.hasDepthData = true;
                                this.isLoadingDepthGraph = false;
                            })
                    } catch {
                        this.hasDepthData = false;
                    }
                }
                else {
                // resolution data
                    try {  
                        fetch(file.url, {
                                mode: 'cors', // no-cors, *cors, same-origin
                            })
                            .then(response => response.json())
                            .then(data => {
                                this.resolutionData = data;
                                this.fillTable('resolution');
                                this.hasResolutionData = true;
                                this.isLoadingResolutionGraph = false;
                            })
                    } catch {
                        this.hasResolutionData = false;
                    }
                }
            })
            // checks if target image exists
            if(this.concentrationTargetImg !=  '') { this.hasConcentrationTarget = true }
            if(this.depthTargetImg !=  '') { this.hasDepthTarget = true }
            if(this.resolutionTargetImg !=  '') { this.hasResolutionTarget = true }
        },
        fillTable(type) {
            if(type == 'concentration') {
                // concentration graph table
                this.concentrationTargetTable = [];
                let fluorophore = Object.values(this.concentrationData['Fluorophore']);
                fluorophore.forEach((fluorophore, index) => {
                    let data = {};
                    data.roi_label = this.concentrationData['ROI label'][index].split(' ')[0];
                    data.mean = this.concentrationData['Mean'][index].toFixed(3);
                    data.std_deviation = this.concentrationData['Standard Deviation'][index].toFixed(3);
                    if(fluorophore == 'IR125') {
                        this.concentrationTargetTable.splice(0, 0, data);
                    }
                })
                if(this.concentrationData['Version'] == 2) {
                    this.concentrationVersion = 2;
                }
            } else if(type == 'depth') {
                // depth graph table
                this.depthTargetTable = [];
                let fluorophore = Object.values(this.depthData['Fluorophore']);
                let controlWellIdx = Object.keys(this.depthData['ROI label']).find(key => this.depthData['ROI label'][key] === "control") 
                fluorophore.forEach((fluorophore, index) => {
                    let data = {};
                    data.roi_label = this.depthData['ROI label'][index].split(' ')[0];
                    data.mean = this.depthData['Mean'][index].toFixed(3);
                    data.std_deviation = this.depthData['Standard Deviation'][index].toFixed(3);
                    if(index != controlWellIdx) {
                        data.cnr = ((data.mean - this.depthData['Mean'][controlWellIdx])/this.depthData['Standard Deviation'][controlWellIdx]).toFixed(2);
                    } else {
                        data.cnr = 'N/A';
                    }
                    this.depthTargetTable.splice(0, 0, data);
                });
                if(this.depthData['Version'] == 2) {
                    this.depthVersion = 2;
                    this.depthTargetTable = this.depthTargetTable.reverse();
                }
            } else if(type == 'resolution') {
                // resolution graph table
                this.resolutionTargetTable = [];
                let resolution = Object.values(this.resolutionData['resolution']);
                resolution.forEach((resolution, index) => {
                    let data = {};
                    if(this.resolutionData['display_element_contrast'][index]) {
                        data.resolution = this.resolutionData['resolution'][index].toFixed(3);
                        if(this.resolutionData['contrast_mean'][index] == null) {
                            data.contrastMean = "N/A";
                        } else {
                            data.contrastMean = String(this.resolutionData['contrast_mean'][index].toFixed(3));
                        }
                        if(this.resolutionData['contrast_std'][index] == null) {
                            data.contrastStd = "N/A";
                        } else {
                            data.contrastStd = String(this.resolutionData['contrast_std'][index].toFixed(3));
                        }
                        this.resolutionTargetTable.push(data);
                    }
                });
                this.resolutionTargetTable.sort((a, b) => (a.resolution > b.resolution) ? 1 : -1);
            }
        },

        isDeviceQueried() {
            if(Object.prototype.hasOwnProperty.call(this.storedImages, `${this.breadcrumbPath.deviceIndex}`) !== undefined) {
                if(Object.prototype.hasOwnProperty.call(this.storedImages, `${this.breadcrumbPath.deviceIndex}`)) {
                    return true;
                }
            }
            return false;
        },

        isModeQueried() {
            if(this.storedImages[`${this.breadcrumbPath.deviceIndex}`] !== undefined) {
                if(Object.prototype.hasOwnProperty.call(this.storedImages[`${this.breadcrumbPath.deviceIndex}`], `${this.breadcrumbPath.modeIndex}`)) {
                    return true;
                }
            }
            return false;
        },

        isImageSetQueried() {
            if(this.storedImages[`${this.breadcrumbPath.deviceIndex}`][[`${this.breadcrumbPath.modeIndex}`]] !== undefined) {
                if(Object.prototype.hasOwnProperty.call(this.storedImages[`${this.breadcrumbPath.deviceIndex}`][`${this.breadcrumbPath.modeIndex}`], `${this.breadcrumbPath.imageSetIndex}`)) {
                    return true;
                }
            }
            return false;
        },

        async queryImages() {
        // entry point of image querying from Firebase storage from SystemCharacterizationWindow
            this.clearValues();
            if(!(this.isDeviceQueried() && this.isModeQueried() && this.isImageSetQueried())) { 
                if (!this.isDeviceQueried()) {
                    this.storedImages[`${this.breadcrumbPath.deviceIndex}`] = {};
                }
                if (!this.isModeQueried()) {
                    this.storedImages[`${this.breadcrumbPath.deviceIndex}`][`${this.breadcrumbPath.modeIndex}`] = {};
                }
                if (!this.isImageSetQueried()) {
                    this.storedImages[`${this.breadcrumbPath.deviceIndex}`][`${this.breadcrumbPath.modeIndex}`][`${this.breadcrumbPath.imageSetIndex}`] = [];
                }
                let imageSetID = this.devices[this.breadcrumbPath.deviceIndex].modes[this.breadcrumbPath.modeIndex].imageSets[this.breadcrumbPath.imageSetIndex].imageSetId;
                let imgObjectRef = this.storedImages[`${this.breadcrumbPath.deviceIndex}`][`${this.breadcrumbPath.modeIndex}`][`${this.breadcrumbPath.imageSetIndex}`];
                let isVerified = await this.checkImageSetVerification(imageSetID);
                this.storedImages[`${this.breadcrumbPath.deviceIndex}`][`${this.breadcrumbPath.modeIndex}`][`${this.breadcrumbPath.imageSetIndex}`].verified = isVerified;
                this.dataIsVerified = isVerified;
                db.collection('groups')
                    .doc(`${this.groupID}`)
                    .collection('imageSets')
                    .doc(`${imageSetID}`)
                    .collection('files')
                    .get()
                    .then((snapshot) => {
                        snapshot.docs.forEach((doc) => {
                            let imgData = doc.data();
                            if(imgData.file_type.split(' ')[1] == 'target'){
                                if(imgData.file_thumbnail_logScale_URL != undefined) {
                                    imgObjectRef.push({
                                        filename: imgData.file_name,
                                        url: imgData.file_thumbnail_URL,
                                        logScaleURL: imgData.file_thumbnail_logScale_URL,
                                        type: imgData.file_type,
                                    });
                                } else {
                                    imgObjectRef.push({
                                        filename: imgData.file_name,
                                        url: imgData.file_thumbnail_URL,
                                        type: imgData.file_type,
                                    });
                                }
                            } else {
                                imgObjectRef.push({
                                    filename: imgData.file_name,
                                    url: imgData.file_URL,
                                    type: imgData.file_type,
                                });
                            }
                        });
                        this.assignURL(imgObjectRef);
                    })
            } else {
                let imgObjectRef = this.storedImages[`${this.breadcrumbPath.deviceIndex}`][`${this.breadcrumbPath.modeIndex}`][`${this.breadcrumbPath.imageSetIndex}`];
                this.dataIsVerified = this.storedImages[`${this.breadcrumbPath.deviceIndex}`][`${this.breadcrumbPath.modeIndex}`][`${this.breadcrumbPath.imageSetIndex}`].verified;
                this.assignURL(imgObjectRef);
            }
            this.$emit('queryFinished');
        },
        checkImageSetVerification(imageSetID) {
            return new Promise(resolve => {
                db.collection('groups')
                .doc(`${this.groupID}`)
                .collection('imageSets')
                .doc(`${imageSetID}`)
                .get()
                .then((data) => {
                    resolve(data.data().verified);
                });
            })
        },
        onCompleteRegression(data) {
            if(data.type == 'concentration') {
                let equation = `y=${data.results.equation[0].toFixed(3)}x^{${data.results.equation[1].toFixed(3)}}`;
                this.concentrationSlopeFit = data.results.equation[1].toFixed(3);
                this.$set(this.concentrationRegression, 'equation', equation);
                let r2 = `R^2: ${data.results.r2.toFixed(3)}`;
                this.$set(this.concentrationRegression, 'r2', r2);
                this.isLoadingConcentrationMetrics = false;
            } 
            else if(data.type == 'depth') {
                console.log(this.depthTargetTable);
                let data_cnr = [];
                let data_counts = [];
                this.depthTargetTable.forEach((well) => {
                    // console.log(parseFloat(well['cnr']))
                    if(well['roi_label'] != 'control'){
                        if(parseFloat(well['cnr'])>0){
                            data_cnr.push([parseFloat(well['roi_label']), parseFloat(well['cnr'])]);
                            data_counts.push([parseFloat(well['roi_label']), parseFloat(well['mean'])]);
                        }
                    }
                })
                let countsRegression = regression.exponential(data_counts);
                let equation = `y=${countsRegression.equation[0].toFixed(3)}e^{${countsRegression.equation[1].toFixed(3)}x}`;
                this.$set(this.depthRegression, 'equation', equation);
                let r2 = `R^2: ${countsRegression.r2.toFixed(3)}`;
                this.$set(this.depthRegression, 'r2', r2);
                
                let cnrRegression = regression.exponential(data_cnr);
                let cnrAt3 = (Math.log(3/cnrRegression.equation[0])/cnrRegression.equation[1]).toFixed(2);
                console.log(data_cnr);
                console.log(cnrRegression);
                console.log(cnrRegression);
                 this.depthCNRAt3 = `${cnrAt3} mm`;
                 console.log(this.depthCNRAt3);

                this.isLoadingDepthMetrics = false;
            } else if(data.type == 'resolution') {
                this.isLoadingResolutionMetrics = false;
            }
        },

    },

    props: {
        groupID: String,
        breadcrumbPath: Object,
        devices: Array,
    }
}
</script>

<style lang="scss" scoped>
    p {
        width: 100%;
        text-align: center;
    }
    figure {
        align-content: center;
        text-align: center;
        align-self: center;
        margin: auto;
    }
    .container {
        margin: 1rem;
    }
    .dataViewer {
        overflow: visible;
    }
    .tile {
        box-shadow: none;
        float: center;
    }
    .sidebar-layout {
        color: white;
        max-height: 700px;
        .b-sidebar {
            max-height: 700px;
        }
    }
    .modalPrompt {
        transition: all 0.2s linear; /* vendorless fallback */
        &:hover {
            transform: scale(1.02);
            background: white;
            box-shadow: 0px 4px 4px 0px rgba(75, 75, 75, 0.2);
        }
    }
    .title {
        word-break: normal;
    }
    .table {
        overflow-y: auto;
    }
    .metrics {
        margin: auto
    }
    .titleTile {
        min-width: 220px;
    }
    .is-prompt {
        background:#db4227cd;
        color: white;  
        position: absolute;
        top: 30%;
    }
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
</style>