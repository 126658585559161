<template>
    <div id="system-characterization" class="columns">
        <div class="rows p-0 max-w-24">
            <h5 class="row title is-5 has-text-black pl-4 pt-4">System Characterization</h5>
            <div class="max-h-screen">
                <DeviceSidebar :fullheight="fullheight" :showImageSets="true" :devices="devices" v-on:deleteSet="deleteSet($event)" @clicked="selectedTab" :activableImageSet='true'/>
            </div>
                <!-- <DataViewer ref="DataViewer" :breadcrumbPath="breadcrumbPath" :groupID="groupID" :devices="devices" :queryImages="queryImages" v-on:queryFinished="queryImages = false"/> -->
        </div>
        <DataViewer class="overflow-scroll" ref="dataViewer" :breadcrumbPath="breadcrumbPath" :groupID="groupID" :devices="devices" v-on:queryFinished="queryImages = false"/>
    </div>
</template>

<script>
import DataViewer from '@appComponents/navbar_tabs/system_characterization/DataViewer'
import DeviceSidebar from '@appComponents/navbar_tabs/general_components/DeviceSidebar'

export default {
    data() {
        return {
            fullheight: false,
            breadcrumbPath: { device: "", mode: "", imageSet: "" },
            queryImages: false,
        }
    },
    components: {
        DataViewer,
        DeviceSidebar
    },
    computed: {
        tabId() {
            return this.$route.params.id;
        },
    },

    methods: {
        deleteSet(setToBeDeleted) {
            this.$emit('deleteSet', setToBeDeleted);
        },
        selectedTab(value) {
            this.breadcrumbPath = value;
            this.$emit('selectedTab', this.breadcrumbPath);

            if(this.breadcrumbPath.imageSetIndex >= 0){
                this.$refs.dataViewer.queryImages();
            }
        },
    },

    watch: {

    },

    props: {
        groupID: String,
        devices: Array
    }

};
</script>

<style lang="scss" scoped>

    .sidebar-layout {
        display: flex;
        flex-direction: row;
        min-height: 100vh;
    }

    .has-image-centered {
        figure {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .is-centered-text {
        text-align: center;
    }

    table {
        margin: auto;
    }
</style>