<template>
    <div id="homepage">
        <User v-slot:user="{ user }">

            <div v-if="user">
                <!-- <Topbar :user="user" v-on:toggleSidebar="toggle = !toggle"></Topbar> -->
                <Sidebar :user="user"/>
            </div>

            <Login v-else />

        </User>
    </div>
</template>

<script>

import Login from '@components/Login';
import User from '@components/User/User';
import Sidebar from '@components/phantom_app/Sidebar';

export default {
    title() {
        return `QUEL FGS`
    },

    data() {
        return {
            tabTitle: 'Home',
        }
    },
    components: {
        Login,
        User,
        Sidebar,
    }
}
</script>

<style>
    #homepage {
        height: 100%;
        overflow-y: hidden;
    }
</style>