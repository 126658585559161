<template>
    <div class="main-page">
        <nav class="navbar is-fixed-top flex" role="navigation" aria-label="main navigation">
            <div class="navbar-brand">
                <a class="navbar-item" v-on:click="toggle()">
                    <i class="material-icons md-24 select-none">menu</i>
                </a>
                <div class="w-24 h-18 p-2" href="https://www.quelimaging.com/" target="_blank">
                    <img id="logo" src="../../assets/QUEL_logo.svg">
                </div>
            </div>
            <!-- <div class="navbar-menu">
                <div class="navbar-end">
                    <div class="navbar-item">
                        <span @mouseover="emailHover = true" @mouseleave="emailHover = false">
                            <a v-if="!emailHover" class="profile has-text-grey">{{ userData.firstName }} {{ userData.lastName }}</a>
                            <a v-else class="profile has-text-grey">{{ userData.email }}</a>
                        </span>
                        <hr class="navbar-divider">
                        <a class="button" @click="auth.signOut()">
                            <span>Logout</span>
                            <span class="icon">
                                <i class="fas fa-sign-out-alt has-background-light"></i>
                            </span>
                        </a>
                    </div>
                </div>
            </div> -->
            <div class="flex-grow"></div>
            <div class="mr-5 ml-5 center space-x-4">
                <div @mouseover="emailHover = true" @mouseleave="emailHover = false">
                    <div v-if="emailHover">
                        {{ userData.email }}
                    </div>
                    <div v-else>
                        {{ userData.firstName }} {{ userData.lastName }}
                    </div>
                </div>
                <div class="flex space-x-2 py-1.5 px-3 border rounded-sm hover:bg-gray-100 text-gray-500 hover:text-blue-400 font-semibold cursor-pointer"
                    @click="auth.signOut()">
                    <div class="cursor-pointer select-none">
                        Logout
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                    </svg>
                </div>
            </div>
        </nav>    
        
        <section class="sidebar-layout pt-14">
             <b-sidebar
                position="static"
                :mobile="mobile"
                :expand-on-hover="expandOnHover"
                :reduce="reduce"
                :fullheight="fullheight"
                type="is-light"
                open>
                <div class="p-1">
                    <div class="block">
                    </div>
                    <b-menu class="is-custom-mobile">
                        <b-menu-list>
                            <!-- <b-menu-item active label="System Monitoring" icon="google-analytics" @click="switchTabs('system_monitoring')"></b-menu-item> -->
                            <b-menu-item label="Home" icon="home" @click="switchTabs('home')" :active="homeIsActive"></b-menu-item>
                        </b-menu-list>
                        <b-menu-list label="Device">
                            <!-- <b-menu-item active label="System Monitoring" icon="google-analytics" @click="switchTabs('system_monitoring')"></b-menu-item> -->
                            <b-menu-item label="System Characterization" icon="chart-timeline-variant" @click="switchTabs('system_characterization')" :active="systemCharacterizationIsActive"></b-menu-item>
                        </b-menu-list>
                        <b-menu-list label="Data">
                            <b-menu-item label="Upload Images" icon="upload" @click="switchTabs('upload_images')" :active="uploadIsActive"></b-menu-item>
                        </b-menu-list>
                        <!-- <b-menu-list label="User">
                            <b-menu-item label="Settings" icon="cog" @click="switchTabs('settings')"></b-menu-item>
                        </b-menu-list> -->
                        <!-- <b-menu-list label="Test Environment">
                            <b-menu-item label="Cloud Function Tester" icon="test-tube" @click="switchTabs('cloud_function_tester')" :active="testEnvironmentIsActive"></b-menu-item>
                        </b-menu-list> -->
                    </b-menu>
                </div>
            </b-sidebar>

            <div class="tool-window min-h-full mt-2">
                <HomeWindow v-if="tab === 'home'" :groupID="groupID" @changeTab="switchTabs($event)"/>
                <!-- <div v-if="tab === 'system_monitoring'"><SystemMonitoringWindow /></div> -->
                <SystemCharacterizationWindow v-if="tab === 'system_characterization'" :groupID="groupID" :devices='devices' @deleteSet="deleteAlert($event)"/>
                <ImageUploadWindow v-if="tab === 'upload_images'" :groupID="groupID" :devices='devices' @refreshMenu='loadMenu()' @deleteSet="deleteAlert($event)" @selectedTab='updatePath($event)'/>
                <!-- <CloudFunctionTester v-if="tab === 'cloud_function_tester'"/> -->
                <!-- <div v-else>Settings</div> -->
            </div>
        </section>
    </div>
</template>

<script>
import HomeWindow from '@appComponents/HomeWindow';
// import SystemMonitoringWindow from '@appComponents/navbar_tabs/SystemMonitoring';
import SystemCharacterizationWindow from '@appComponents/navbar_tabs/system_characterization/SystemCharacterizationWindow';
import ImageUploadWindow from '@appComponents/navbar_tabs/image_uploader/ImageUploadWindow';
import { auth, db, storage } from '@src/firebase'

// import CloudFunctionTester from '@appComponents/navbar_tabs/CloudFunctionTester';

export default {

    data() {
        return {
            auth,
            storage,
            emailHover: false,
            devices: [],
            userData: Object,
            groupID: null,
            isLoading: true,
            tab: 'home',
            expandOnHover: false,
            mobile: "reduce",
            reduce: false,
            fullheight: true,
            userFolder: Object,    
            selectedPath: { device: "", mode: "" },    
            // reactive tabs
            homeIsActive: true,
            systemCharacterizationIsActive: false,
            uploadIsActive: false,
            testEnvironmentIsActive: false,
        };
    },

    components: {
        HomeWindow,
        SystemCharacterizationWindow,
        ImageUploadWindow,
        // CloudFunctionTester
    },

    created() {
        // search db for group logged in user belongs to
        db.collection('users')
            .where('uid', '==', this.user.uid)
            .limit(1)
            .get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    this.userData = doc.data();
                    this.groupID = this.userData.groupID;
                    this.loadMenu();
                })
            })
    },

    methods: {
        toggle() {
            this.reduce = !this.reduce;
        },

        resetTabs() {
            this.homeIsActive = false;
            this.systemCharacterizationIsActive = false;
            this.uploadIsActive = false;
            this.testEnvironmentIsActive = false;
        },

        switchTabs(tab) {
            this.tab = tab;
            this.resetTabs();
            switch(tab) {
                case 'home':
                    this.homeIsActive = true;
                    break;
                case 'system_characterization':
                    this.systemCharacterizationIsActive = true;
                    break;
                case 'upload_images':
                    this.uploadIsActive = true;
                    break;
                case 'cloud_function_tester':
                    this.testEnvironmentIsActive = true;
                    break;

            }

        },

        updatePath(path) {
            this.selectedPath.device = path.device;
            this.selectedPath.mode = path.mode;
        },

        loadMenu() { 
            this.devices = [];
            this.getDevices()
                .then(() => {

                    this.devices.forEach((device, deviceIndex) => {
                        this.getModes(device).then(() => {

                            this.devices[deviceIndex].modes.forEach((mode) => {
                                this.getImageSets(device, mode);
                                // this.devices[deviceIndex].modes[modeIndex].dynamicSort('imageSetName');
                            })

                        })
                    })
                });
            
            // this.isLoading = false 
        },

        getDevices() {
            return new Promise((resolve) => {
                db.collection('groups').doc(`${this.groupID}`)
                    .collection('devices')
                    .get()
                    .then((snapshot) => {
                        snapshot.forEach((doc) => {
                            this.devices.push( {
                                'deviceName': doc.data().deviceName,
                                'deviceId': doc.id,
                                'modes': []
                            });
                            this.devices.sort(this.dynamicSort('deviceName'));
                        })
                        resolve();
                    })
            })
        },

        getModes(device) {
            let index = this.devices.findIndex(d => d.deviceName === device.deviceName);

            return new Promise ((resolve) => {
                db.collection('groups').doc(`${this.groupID}`)
                    .collection('modes')
                    .where('parentDevice', '==', `${device.deviceName}`)
                    .get()
                    .then((snapshot) => {
                        snapshot.forEach((doc) => {
                            this.devices[index].modes.unshift({
                                'modeName': doc.data().modeName,
                                'modeId': doc.id,
                                'imageSets': []
                            });
                            this.devices[index].modes.sort(this.dynamicSort('modeName'));
                        })
                        resolve();
                    })
            })
        },

        getImageSets(device, mode) {
            let indexDevice = this.devices.findIndex(d => d.deviceName === device.deviceName);
            let indexMode = this.devices[indexDevice].modes.findIndex(m => m.modeName === mode.modeName);

            let imageSetFolder = db.collection('groups')
                            .doc(`${this.groupID}`)
                            .collection('imageSets');
            
            return new Promise ((resolve) => {
                imageSetFolder
                    .where('parentDevice', '==', this.devices[indexDevice].deviceName)
                    .where('parentMode', '==', this.devices[indexDevice].modes[indexMode].modeName)
                    .get()
                    .then((imageSets) => {
                        imageSets.forEach((set) => {
                            db.collection('groups')
                                .doc(`${this.groupID}`)
                                .collection('imageSets')
                                .doc(`${set.id}`)
                                .collection('files')
                                .get()
                                .then((files) => {
                                    let imageSetFiles = [];
                                    files.forEach((file) => {
                                        imageSetFiles.push({
                                            fileName: file.data().file_name,
                                            fileType: file.data().file_type
                                        })
                                    })

                                    this.devices[indexDevice].modes[indexMode].imageSets.push({
                                        'imageSetName': set.data().setName,
                                        'imageSetId': set.id,
                                        'createdOn': this.getDate(set.data().createdOn.toDate()),
                                        'images': imageSetFiles,
                                    })
                                    this.devices[indexDevice].modes[indexMode].imageSets.sort(this.dynamicSort('imageSetName'));
                                });
                        })
                        resolve();
                    })
            })
        },

        dynamicSort(property) {
            var sortOrder = 1;

            if(property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }

            return function (a,b) {
                if(sortOrder == -1){
                    return b[property].localeCompare(a[property]);
                }else{
                    return a[property].localeCompare(b[property]);
                }        
            }
        },

        getDate(date) {
            let minutes = date.getMinutes().toString().length == 1 ? '0'+date.getMinutes() : date.getMinutes(),
            hours = date.getHours().toString().length == 1 ? '0'+date.getHours() : date.getHours(),
            // ampm = date.getHours() >= 12 ? 'pm' : 'am',
            months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
            days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
        return days[date.getDay()]+' '+months[date.getMonth()]+'-'+date.getDate()+'-'+date.getFullYear()+' '+hours+':'+minutes;//+ampm;
        },

        deleteAlert(setToBeDeleted) {
            this.$buefy.dialog.confirm({
                title: 'Delete Image Set',
                message: `Are you sure you want to delete image set "${setToBeDeleted.imageSet}" in ${setToBeDeleted.device} - ${setToBeDeleted.mode}? This action cannot be undone.`,
                confirmText: 'Delete Image Set',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    db.collection('groups')
                    .doc(`${this.groupID}`)
                    .get()
                    .then(() => {
                        // let docId = snapshot.docs[0].id;
                        let docPath = db.collection('groups').doc(`${this.groupID}`).collection('imageSets');
                        docPath
                        .where('setName', '==', setToBeDeleted.imageSet)
                        .where('parentMode', '==', setToBeDeleted.mode)
                        .where('parentDevice', '==', setToBeDeleted.device)
                        .get()
                        .then((snapshot) => {
                            let imageSetId = snapshot.docs[0].id;
                            docPath.doc(imageSetId).collection('files').get().then((files) => {
                                files.forEach((file) => {
                                    docPath.doc(imageSetId).collection('files').doc(file.id).delete();
                                })
                                docPath.doc(imageSetId).delete().then(() => {
                                    db.collection('groups').doc(`${this.groupID}`)
                                            .collection('devices')
                                            .where('deviceName', '==', setToBeDeleted.device)
                                            .get()
                                            .then((snapshot) => {
                                                    let deviceId = snapshot.docs[0].id;
                                                    db.collection('groups').doc(`${this.groupID}`)
                                                    .collection('modes')
                                                    .where('modeName', '==', setToBeDeleted.mode)
                                                    .get()
                                                    .then((snapshot) => {
                                                            let modeId = snapshot.docs[0].id;
                                                            // console.log(`Path: users/${this.user.uid}/${deviceId}/${modeId}/${imageSetId}`);
                                                            // const storage = new Storage();
                                                            // const bucket = storage.bucket('default');
                                                            // const bucket = storage.bucket();
                                                            storage
                                                                .ref('groups') // users root folder
                                                                .child(`${this.groupID}`)  // group id
                                                                .child(`${deviceId}`)  // devices
                                                                .child(`${modeId}`) // modes
                                                                .child(`${imageSetId}`)
                                                                .listAll()
                                                                .then((res) => {
                                                                    res.prefixes.forEach((folderRef) => {
                                                                    // All the prefixes under listRef.
                                                                    // You may call listAll() recursively on them.
                                                                        // console.log(`in folderRef: ${folderRef}`);
    
                                                                        // // Delete the file
                                                                        folderRef.delete().then(() => {
                                                                        // File deleted successfully
                                                                        }).catch((error) => {
                                                                        // Uh-oh, an error occurred!
                                                                            console.log(error);
                                                                        });
                                                                    });
                                                                    res.items.forEach((itemRef) => {
                                                                    // All the items under listRef.
                                                                        // console.log(`in itemRef: ${itemRef}`);
                                                                        
                                                                        // Delete the file
                                                                        itemRef.delete().then(() => {
                                                                        // File deleted successfully
                                                                            // console.log("file deleted successfully");
                                                                        }).catch((error) => {
                                                                        // Uh-oh, an error occurred!
                                                                            console.log(error);
                                                                        });
                                                                    });
                                                                }).catch((error) => {
                                                                    // Uh-oh, an error occurred!
                                                                    console.log(error);
                                                                });
                                                        }
                                                    )
                                                }
                                            )
                                    this.loadMenu(); // **** add previous selection ****
                                })
                            })
                        })
                    }) 
                }
            })
        },
    },

    props: {
        user: Object
    },
    
}
</script>

<style lang="scss">
    #logo {
        width: 100px;
        min-height: 5vh;
        /* margin-left: 1rem; */
    }

    #logout_button {
        margin-left: 0.5rem;
    }

    .profile {
        margin-right: 1rem;
    }

    .b-sidebar .sidebar-content {
        box-shadow: none;
    }
    .tool-window {
        padding: 1em;
    }
    .main-page {
        display: flex;
        flex-direction: column;
        width: 100%;
        // min-height: 100%;
        min-height: 100vh;
        .sidebar-layout {
            display: flex;
            flex-direction: row;
            // min-height: 100%;
            min-height: 100vh;
        }
    }
    .menu-list {
        white-space: nowrap;
        a{
            max-height: 36px;
        }
    }

    .tool-window {
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        .b-sidebar {
            .sidebar-content {
                &.is-mini-mobile {
                    &:not(.is-mini-expand),
                    &.is-mini-expand:not(:hover) {
                        .menu-list {
                            li {
                                a {
                                    span:nth-child(2) {
                                        display: inline-block;
                                        visibility: hidden;
                                        transition: visibility 0s;
                                    }
                                }
                                ul {
                                    padding-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (min-width: 768px) {
        .b-sidebar {
            .sidebar-content {
                &.is-mini {
                    &:not(.is-mini-expand),
                    &.is-mini-expand:not(:hover) {
                        .menu-list {
                            li {
                                a {
                                    span:nth-child(2) {
                                        display: inline-block;
                                        visibility: hidden;
                                        transition: visibility 0s;
                                    }
                                }
                                ul {
                                    padding-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>