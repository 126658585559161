// require('@/assets/sass/main.scss');
import Vue from 'vue'
import App from './App.vue'
import { firestorePlugin } from 'vuefire'
import VueRouter from 'vue-router'
// buefy imports for css styles
import Buefy from 'buefy'

// css imports
import 'buefy/dist/buefy.css'
import './assets/styles/main.scss'
// import './assets/styles/debug.css'
import 'katex/dist/katex.min.css';

import titleMixin from './js/titleMixin'

import VueCompositionApi from '@vue/composition-api'
import { Chart } from "chart.js"
import { ChartRegressions } from "chartjs-plugin-regression"
import VueKatex from 'vue-katex';
import VTooltipPlugin from 'v-tooltip'
import 'v-tooltip/dist/v-tooltip.css'

// import embed from 'vega-embed'

Vue.use(VueCompositionApi)
Vue.use(VueRouter)
Vue.use(firestorePlugin)

Vue.use(Buefy)

Vue.mixin(titleMixin)
Chart.plugins.register(ChartRegressions)
Chart.defaults.global.defaultFontSize = 14

Vue.use(VueKatex);

Vue.use(VTooltipPlugin)

Vue.config.productionTip = false

import Home from './components/Home'
import Sidebar from './components/phantom_app/Sidebar'
import ResetPassword from './components/ResetPassword'

const router = new VueRouter({
  mode: 'history',
  history: true,
  routes: [
    { path: '/', component: Home },
    { path: '/home', name: 'home', component: Sidebar },
    { path: '/reset-password', component: ResetPassword }
  ]
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
