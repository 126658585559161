import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';

let firebaseConfig;

firebaseConfig = { 
    apiKey: "AIzaSyCNsGU7ueKkBEnBLGcKk2naLz4E5Y0-WB4",
    authDomain: "phantom-reporting.firebaseapp.com",
    projectId: "phantom-reporting",
    storageBucket: "phantom-reporting.appspot.com",
    messagingSenderId: "779127729923",
    appId: "1:779127729923:web:c5b588b48091c9e36fc336",
    measurementId: "G-P94H9J07LT"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();

let useEmulator = false;

if(useEmulator) {
    auth.useEmulator("http://localhost:9098");
    db.useEmulator("localhost", 9080);
    storage.useEmulator("localhost", 9198);
    functions.useEmulator("localhost", 9081);
}