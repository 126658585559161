<template>
  <div>
      <ScatterChart class="chart" :chartSettings="chartSettings" :ticks='resolutionTicks' :responsive="responsive" :fontSize="fontSize" :height="height" :width="width" :graphData="datacollection" @onCompleteRegression="onCompleteRegression"></ScatterChart>
      <div v-if="type == 'tile'">
        <svg class="magnify h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
      </div>
  </div>
</template>

<script>
  import ChartRegressions from 'chartjs-plugin-regression'
  import ScatterChart from '@appComponents/navbar_tabs/general_components/ScatterChart'

  export default {
    components: {
      ScatterChart,
    },
    data () {
      return {
        num: 50,
        ChartRegressions,
        regressionResults: null,
        fontSize: null,
        datacollection: null,
        controlData: null,
        qWell: null,
        resolutionData: [],
        resolutionDataLabels: [],
        resolutionTicks: [],
        resolution: null,
        mean: null,
        chartSettings: {},
      }
    },
    mounted() {
      this.setOptions();
      this.setResolutionData();
      this.fillData();
      this.setFont();
    },
    methods: {
      test() {
      },
      setOptions() {
        this.chartSettings = {
          title: 'Resolution Target',
          xAxisLabel: 'Resolution (lp/mm)',
          yAxisLabel: 'Contrast (%)',
          xAxisScale: 'linear',
          yAxisScale: 'linear',
          axisBounds: {
            xMin: 0.5,
            xMax: null,
            yMin: 0,
            yMax: 100,
          },
          stepSize: 0.5,
        }
      },
      setResolutionData() {
        let contrastMean = Object.values(this.graphData['contrast_mean'])
        let resolution = Object.values(this.graphData.resolution);
        this.resolutionDataLabels = resolution;

        for(let i = 0; i < resolution.length; i++) {
          if(this.graphData['display_element_contrast'][i]) {
            this.resolutionData.push({
              x: resolution[i],
              y: contrastMean[i],
            });
          }
        }
      },
      setFont() {
        if(this.type == 'tile') {
          this.fontSize = 14;
        }
        else {
          this.fontSize = 22;
        }
      },
      fillData () {
        // let endIndex = 0;
        // this.resolutionData.forEach((elem) => {
        //   if(elem.y != null) { endIndex++ };
        // })
        this.datacollection = {
            // labels: this.resolution,
            datasets: [
              {
                  label: 'Resolution Target',
                  data: this.resolutionData,
                  hoverBackgroundColor: '#2196f3',
                  backgroundColor: '#74bcf2',
                  pointRadius: 4,
                  pointHoverRadius: 8,
                  pointHitRadius: 3,
                  // regressions: {
                  //   type: ['polynomial'],
                  //   calculation: { precision: 10, order: 3 },
                  //   line: { color: "#2196f3", width: 2 },
                  //   sections: [{startIndex: 0, endIndex: }]
                  // },
              }
            ]
        }
      },
      onCompleteRegression(chart) {
          if(this.type == 'tile') {
            this.regressionResults = chart.regressions.getSections(chart.graphData, 0);
            if(this.regressionResults != undefined) {
              let regression = {
                type: 'resolution',
                results: this.regressionResults[0].result,
              }
              this.$emit('onCompleteRegression', regression);
          }
        }
      }
    },
    watch: {
    },
    props: {
      graphData: Object,
      height: Number,
      width: Number,
      type: String,
      responsive: Boolean,
    },
  }
</script>

<style lang="scss">
  .modal-content {
      overflow: visible,
  }
  .magnify {
      position: relative;
      height: 20px;
      float: right;
      &:hover {
        color: rgb(169, 169, 169);
      }
  }
</style>