<template>
    <section class="sidebar-layout bg-white select-none">
        <b-sidebar
            position="static"
            :expand-on-hover="expandOnHover"
            :fullheight="fullheight"
            type="is-white"
            scroll="clip"
            open>
            <div class="h-screen px-4 p-4 no-scrollbar overflow-y-scroll">
                <div class="block">
                </div>
                <b-menu class="is-custom-mobile">
                    <b-menu-list class="flex flex-coll no-scrollbar">
                        <template #label>
                            <div class="flex space-x-1">
                                <div>
                                    Devices
                                </div>
                                <div class="has-tooltip">
                                    <div class='w-48 tooltip rounded shadow-lg p-4 bg-gray-200 text-black text-center font-semibold -mt-4 ml-5'>
                                        Select a device and mode below for the image set to be uploaded to
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                            </div>
                        </template>
                        <b-menu-item v-for="(device, deviceIndex) in devices" 
                                    :label="device.deviceName" 
                                    :key="device.deviceName" 
                                    @click='tabSelected({type: "device", name: device.deviceName, index: deviceIndex})'>
                                <b-menu-item v-for="(mode, modeIndex) in device.modes" 
                                            :label="mode.modeName" :key="mode.modeName" 
                                            @click='tabSelected({type: "mode", name: mode.modeName, index: modeIndex})'>
                                    <b-menu class="is-custom-mobile" :activable="activableImageSet">
                                        <b-menu-list>
                                            <template #label>
                                            Uploaded Sets
                                        </template>
                                            <b-menu-item class="" v-for="(imageSet, imageSetIndex) in mode.imageSets" :key="imageSet.imageSetName" v-on:click='tabSelected({type: "imageSet", name: imageSet.imageSetName, index: imageSetIndex})'>
                                                <template #label>
                                                    <div class="flex">
                                                        <div class="flex w-32 no-scrollbar overflow-scroll">
                                                            <div class="autoscroll-scrollbar">
                                                                {{imageSet.imageSetName}}
                                                            </div>
                                                        </div>
                                                        <VMenu placement="right-start" delay="0" :triggers="['click']">
                                                            <button @click="openInfoTab(imageSetIndex)">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                                                                </svg>
                                                            </button>
                                                            <template #popper>
                                                                <div>
                                                                    <div class="p-2 w-72 bg-white hover:bg-gray-200 select-none">
                                                                        <div @click="toggleInfo()">
                                                                            Info
                                                                        </div>
                                                                        <div v-if="showInfo"
                                                                            class="bg-white mt-2">
                                                                            <pre class="has-text-weight-bold">
Date uploaded:
{{imageSet.createdOn}} <br>
Images in set:<span v-for="image in imageSet.images" :key="image.fileName">
Name:       {{image.fileName}}
File type:  {{image.fileType}}
</span>
                                                                        </pre>
                                                                    </div>
                                                                </div>
                                                                <div class="p-2 bg-white hover:bg-gray-200">
                                                                    <div @click="deleteSet({imageSet: imageSet.imageSetName, mode: mode.modeName, device: device.deviceName})">
                                                                        Delete Set
                                                                    </div>
                                                                </div>
                                                                </div>
                                                                <!-- <div v-if="isInfoTabActive" :class="[ activeInfoTab == imageSetIndex ? 'visible' : 'invisible']"
                                                                    class="z-50 absolute ml-8 -mt-8 text-black font-semibold shadow-lg space-y-1 rounded-sm bg-gray-50">
                                                                    <div class="p-3 w-72 bg-gray-50 hover:bg-gray-200">
                                                                        <div @click="toggleInfo()">
                                                                            Info
                                                                        </div>
                                                                        <div v-if="showInfo"
                                                                            class="bg-white mt-2">
                                                                            <pre class="has-text-weight-bold">
Date uploaded:
{{imageSet.createdOn}} <br>
Images in set:<span v-for="image in imageSet.images" :key="image.fileName">
Name:       {{image.fileName}}
File type:  {{image.fileType}}
</span>
                                                                        </pre>
                                                                    </div>
                                                                </div>
                                                                <div class="z-0 p-3 bg-gray-50 hover:bg-gray-200">
                                                                    <div @click="deleteSet({imageSet: imageSet.imageSetName, mode: mode.modeName, device: device.deviceName})">
                                                                        Delete Set
                                                                    </div>
                                                                </div>
                                                            </div> -->
                                                            </template>
                                                        </VMenu>
                                                        <!-- <div class="has-infoTab">
                                                            <div @click="openInfoTab(imageSetIndex)">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                                                                </svg>
                                                            </div>
                                                            <div v-if="isInfoTabActive" :class="[ activeInfoTab == imageSetIndex ? 'visible' : 'invisible']"
                                                                class="z-50 absolute ml-8 -mt-8 text-black font-semibold shadow-lg space-y-1 rounded-sm bg-gray-50">
                                                                <div class="p-3 w-72 bg-gray-50 hover:bg-gray-200">
                                                                    <div @click="toggleInfo()">
                                                                        Info
                                                                    </div>
                                                                    <div v-if="showInfo"
                                                                        class="bg-white mt-2">
                                                                        <pre class="has-text-weight-bold">
Date uploaded:
{{imageSet.createdOn}} <br>
Images in set:<span v-for="image in imageSet.images" :key="image.fileName">
Name:       {{image.fileName}}
File type:  {{image.fileType}}
</span>
                                                                        </pre>
                                                                    </div>
                                                                </div>
                                                                <div class="z-0 p-3 bg-gray-50 hover:bg-gray-200">
                                                                    <div @click="deleteSet({imageSet: imageSet.imageSetName, mode: mode.modeName, device: device.deviceName})">
                                                                        Delete Set
                                                                    </div>
                                                                    <div></div>
                                                                </div>
                                                            </div>
                                                        </div> -->

                                                        <!-- <b-dropdown aria-role="list" class="infoTab flex" position="is-bottom-right" :close-on-click="closeOnClick">
                                                            <template #trigger>
                                                                <b-icon icon="dots-vertical"></b-icon>
                                                            </template>
                                                            <b-collapse :open="false">
                                                                <template #trigger>
                                                                    <b-dropdown-item aria-role="listitem">Info</b-dropdown-item>
                                                                </template>
                                                                <div class="info-window notification is-light">
                                                                    <div class="content">
                                                                        <pre class="has-text-weight-bold">
Date uploaded:
{{imageSet.createdOn}} <br>
Images in set:<span v-for="image in imageSet.images" :key="image.fileName">
Name:       {{image.fileName}}
File type:  {{image.fileType}}
</span>
                                                                        </pre>
                                                                    </div>
                                                                </div>
                                                            </b-collapse>
                                                            <b-dropdown-item aria-role="listitem" v-on:click="deleteSet({imageSet: imageSet.imageSetName, mode: mode.modeName, device: device.deviceName})">Delete Set</b-dropdown-item>
                                                        </b-dropdown> -->
                                                    </div>
                                                </template>
                                            </b-menu-item>
                                        </b-menu-list>
                                    </b-menu>
                                </b-menu-item>
                        </b-menu-item>
                        <div class="h-96 w-full"></div>
                    </b-menu-list>
                </b-menu>
            </div>
        </b-sidebar>
    </section>
</template>

<script>
export default {
    data() {
        return {
            closeOnClick: false,
            expandOnHover: true,
            reduce: false,
            breadcrumbPath: { device: "", mode: "", imageSet: "" },
            activeInfoTab: null,
            isInfoTabActive: false,
            showInfo: true,
        }
    },
    props: {
        devices: Array,
        fullheight: Boolean,
        activableImageSet: Boolean,
    },
    created: function () {
    },

    methods: {
        openInfoTab(index) {
            if(index == this.activeInfoTab && this.isInfoTabActive) { 
                this.isInfoTabActive = false;
            } else {
                this.activeInfoTab = index;
                this.isInfoTabActive = true;
            }
        },
        toggleInfo() {
            this.showInfo = !this.showInfo;
        },
        tabSelected(tab) {
            if(tab.type === 'device'){
                this.breadcrumbPath.device = tab.name; 
                this.breadcrumbPath.deviceIndex = tab.index;

                this.breadcrumbPath.mode = "";
                this.breadcrumbPath.imageSet = "";

                this.breadcrumbPath.modeIndex = -1;
                this.breadcrumbPath.imageSetIndex = -1;

                this.$emit('clicked', this.breadcrumbPath);
            }
            if(tab.type === 'mode'){
                this.breadcrumbPath.mode = tab.name;
                this.breadcrumbPath.modeIndex = tab.index;

                this.breadcrumbPath.imageSet = "";
                this.breadcrumbPath.imageSetIndex = -1;

                this.$emit('clicked', this.breadcrumbPath);
            }
            if(tab.type === 'imageSet'){
                this.breadcrumbPath.imageSet = tab.name;
                this.breadcrumbPath.imageSetIndex = tab.index;
                this.$emit('clicked', this.breadcrumbPath);
            }
        },
        
        deleteSet(setToBeDeleted){
            this.$emit('deleteSet', setToBeDeleted);
        },
    }
}
</script>

<style lang="scss" scoped>
    .notFullHeight.sidebar-layout {
        height: 700px;
        min-height: 0vh;
    }

    .notification {
        width: 360px;
    }

    .dropdown-item {
        &:hover {
            color:#363636;
            background: #f5f5f5;
        }
    }

    .menu-label {
        .tool-tip {
            text-transform: none;
            position: relative;
        }
    }

    .content {
        overflow-y: scroll;
    }

    .info-window {
        padding: 0.5rem;
    }
    .autoscroll-scrollbar {
        -webkit-transition: 8.5s;
        -moz-transition: 8.5s;
        transition: 8.5s;
        transition-property: margin-left;
        
        -webkit-transition-timing-function: linear;
        -moz-transition-timing-function: linear;
        transition-timing-function: linear;

        &:hover {
            transition-delay: 1s;
            margin-left: -300px;
        }
    }
    // .infoTab {
    //     @apply z-10 relative invisible;
    //     transition:visibility 0.3s linear,opacity 0.3s ease-in-out;
    //     opacity: 0;
    // }

    //     .has-infoTab .infoTab {
    //     @apply z-50 relative visible;
    //     transition:visibility 0.3s linear,opacity 0.3s ease-in-out;
    //     opacity: 1;
    // }
    .infoTab { 
        position: relative;
        z-index: 9999;
    }
</style>