<template>
    <section class="legend">
        <div v-if="type != 'resolutionTarget'">
            <b-collapse
                class="card"
                animation="fade"
                v-for="(collapse, index) of collapses"
                :key="index"
                :open="isOpen == index"
                @open="isOpen = index">
                <template #trigger="">
                    <div
                        class="card-header"
                        role="button"
                    >
                    <div class="dot" :style="{ background: collapse.color }"></div>
                        <p class="card-header-title" style="user-select: none;">
                            {{ collapse.title }}
                        </p>
                    </div>
                </template>
                <div class="card-content">
                    <div class="content">
                        {{ collapse.text }}
                    </div>
                </div>
            </b-collapse>
        </div>
        <div v-else>
            <b-collapse
                class="card"
                animation="fade"
                v-for="(collapse, index) of collapses"
                :key="index"
                :open="isOpen == index"
                @open="isOpen = index">
                <template #trigger="">
                    <div
                        class="card-header"
                        role="button"
                    >
                    <div class="dot" :style="{ background: collapse.color }"></div>
                        <p class="card-header-title" style="user-select: none;">
                            {{ collapse.title }}
                        </p>
                    </div>
                </template>
                <div v-if="collapse.text != ''" class="card-content">
                    <div class="content">
                        {{ collapse.text }}
                    </div>
                </div>
                <div v-if="collapse.hasTable">
                    <div class="p-2 m-auto mb-4 overflow text-center">
                        <img class="h-32 w-32 m-auto mb-2" src="https://upload.wikimedia.org/wikipedia/commons/a/a5/SilverFast_Resolution_Target_USAF_1951.png" alt="">
                        <a class="hover:text-blue-300 text-blue-500" href="https://en.wikipedia.org/wiki/1951_USAF_resolution_test_chart" target="_blank">SilverFast USAF 1951 Resolution Target by LaserSoft Imaging</a>
                    </div>
                    <div>
                        <p class="font-bold mb-4">
                            Number of Line Pairs / mm in USAF Resolving Power Test Target 1951
                        </p>
                        <div class="bg-gray-300 h-0.5"></div>
                        <b-table class="table" 
                        :data="collapse.data" 
                        :columns="collapse.columns" 
                        :mobile-cards="false" 
                        :sticky-header="true" 
                        :narrowed="true">
                            <p class="font-semibold text-center">
                                Group Number
                            </p>
                        </b-table>
                    </div>
                </div>
            </b-collapse>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                isOpen: null,
                collapses: this.getLegendValues()
            }
        },
        methods: {
            getLegendValues() {
                let legendValues = [];
                if(this.type != 'resolutionTarget') {
                    legendValues.push({
                        title: 'ICG*',
                        text: 'ICG equivalence achieved with laser dye that matches ICG spectral absorption, emission, and fluorescence yield.',
                        color: '#74bcf2'
                    });
                    if(this.type == 'concentrationTarget') {
                        if(this.version < 2){
                            legendValues.push(
                            {
                                title: 'QD800',
                                text: 'Targets use cadmium-based quantum dots with broad absorptiion and emission peak at 800 nm.',
                                color: '#af0056'
                            })
                        }
                        legendValues.push({
                            title: 'Control',
                            text: 'Control well uses the same base formulation as the ICG* and QD800 wells with no fluorophore.',
                            color: '#f6a770'
                        });
                    }
                } else {
                    legendValues.push({
                        title: 'About USAF 1951',
                        text: 'USAF 1951 negative chrome resolution chart backed with 1000nM ICG-equivalent material as illuminant. This target is used to measure fluorescence spatial resolution ranging from Group 0 (1 lp/mm) through Group 7 (228.1 lp/mm). Standard analysis is  only performed for Groups 0-2. If your application requires analysis of Groups 3-7, please contact QUEL imaging support at: info@QUELimaging.com',
                        color: '#74bcf2'
                    },
                    {
                        title: "USAF Resolving Power Test Target 1951",
                        text: '',
                        color: '#74bcf2',
                        hasTable: true,
                        data: [
                            { 'element': 1, '0': '1.00', '1': '2.00', '2': '4.00' },
                            { 'element': 2, '0': '1.12', '1': '2.24', '2': '4.49' },
                            { 'element': 3, '0': '1.26', '1': '2.52', '2': '5.04' },
                            { 'element': 4, '0': '1.41', '1': '2.83', '2': '5.66' },
                            { 'element': 5, '0': '1.59', '1': '3.17', '2': '6.35' },
                            { 'element': 6, '0': '1.78', '1': '3.56', '2': '7.13' },
                        ],
                        columns: [
                            {
                                field: 'element',
                                label: 'element',
                                numeric: true,
                                centered: true,
                            },
                            {
                                field: '0',
                                label: '0',
                                numeric: true,
                                centered: true,
                            },
                            {
                                field: '1',
                                label: '1',
                                numeric: true,
                                centered: true,
                            },
                            {
                                field: '2',
                                label: '2',
                                numeric: true,
                                centered: true,
                            },
                        ]
                    });
                }
                return legendValues;
            }
        },
        props: {
            type: String,
            version: Number,
        }
    }
</script>

<style lang="scss">
    .legend {
        height: 540px;
        overflow-y: auto;
    }
    .dot {
        margin: auto;
        margin-left: 10px;
        min-height: 15px;
        min-width: 15px;
        border-radius: 50%;
        display: inline-block;
    }
    .card-header {
        box-shadow: none;
    }
    .collapse.card {
        box-shadow: none;
        .card-header:hover {
            background: rgb(225, 225, 225);
        }
    }
</style>