<template>
    <div>
        <div class="columns">
            <div class="column is-half">
                <div class="red">
                    <p class="filename">{{file.name}}</p>
                </div>
            </div>
            <div class="column is-one-third">
                <div class="green">
                    <p class="has-text-weight-bold">
                        <b-field :type="{'is-danger': duplicateError }" :label-position="labelPosition">
                            <b-select placeholder="Select image type" @change.native='updateFileType' :disabled='isUploading'>
                                <option
                                    v-for="option in options"
                                    :value="option.fileType"
                                    :key="option.fileType"
                                    required>
                                        {{ option.fileType }}
                                    </option>
                            </b-select>
                        </b-field>
                    </p>
                </div>
            </div>
            <div class="column blue deleteButton">
                <b-button :disabled="isUploading" type="is-danger" @click="deleteFile" icon-right="delete" />
            </div>
        </div>
        <div v-if="isUploading" class="row">
            <b-progress type="is-primary" :value="uploadProgress" show-value format="percent"></b-progress>
        </div>
    </div>
</template>

<script>
import { storage } from '@src/firebase';
import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
    data() {
        const options = [
            { 'fileType': 'Concentration Target'},
            { 'fileType': 'Depth Target'},
            { 'fileType': 'Resolution Target'},
            { 'fileType': 'Color'},
            { 'fileType': 'Other' }
        ]

        return {
            uploadProgress: 0,
            options,
            fileType: '',
            uploading: false,
            uploadFinished: false,
            labelPosition: 'on-border',
            url: String,
            state: true,
        }
    },

    created() {
        this.$emit('updateFileType', this.fileType);
    },

    props: {
        file: File,
        fileIndex: Number,
        isUploading: Boolean,
        uploadPath: Object,
        duplicateError: Boolean,
    },

    methods: {
        deleteFile() {
            this.$emit('deleteFile', this.fileType);
        },

        updateFileType() {
            this.fileType = event.target.value;
            this.$emit('updateFileType', this.fileType);
        },

        async uploadFile() {

            let metadata = {
                customMetadata: {
                    file_type: this.fileType.toLowerCase(),
                }
            };

            let folderPath =  `groups/${this.uploadPath.groupID}/${this.uploadPath.deviceFolder.deviceId}/${this.uploadPath.modeFolder.modeId}/${this.uploadPath.docRef.id}/${this.file.name}`;
            // console.log(folderPath);
            this.uploadPath.path.doc(this.uploadPath.docRef.id).collection('files').add({
                file_path: folderPath
            }).then((docSnapshot) => {
                    let docId = docSnapshot.id;

                    let uploadTask = storage
                        .ref('groups') // group root folder
                        .child(`${this.uploadPath.groupID}`)  // user id
                        .child(`${this.uploadPath.deviceFolder.deviceId}`)  // devices
                        .child(`${this.uploadPath.modeFolder.modeId}`) // modes
                        .child(`${this.uploadPath.docRef.id}`) // image set ****************************
                        .child(this.file.name)  // image name
                        .put(this.file, metadata);  // upload
                        
                    uploadTask.on('state_changed', 
                        (snapshot) => {
                            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            this.uploadProgress = progress;
                        }, 
                        // error code
                        (error) => {
                            console.log('Error: ' + error);
                            this.uploadPath.path.doc(this.uploadPath.docRef.id).collection('files').doc(docId).delete();
                        }, 
                        // returns url when finished
                        () => {
                            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                                // console.log('File available at', downloadURL);
                                this.url = downloadURL;
                                 // *** make sure to emit url
                                let dbFields = {
                                    file_name: uploadTask.snapshot.ref.name,
                                    file_URL: downloadURL,
                                    file_type: this.fileType.toLowerCase(),
                                    createdOn: firebase.firestore.FieldValue.serverTimestamp(),
                                }
                                
                                this.uploadPath.path.doc(this.uploadPath.docRef.id).collection('files').doc(docId).update(dbFields);
                                
                                this.$emit('uploadFinished');
                            });
                        }
                    )
                })
        },
    },

    watch: {
        isUploading: function() {
            if(this.isUploading) {
                this.uploadFile();
            }
        }
    }
}
</script>

<style lang='scss' scoped>
    .level-item {
        overflow: scroll;
    }
    .column {
        overflow: auto;
        padding-right: 0px;
    }
    .filename {
        word-break: break-all;
    }
    .deleteButton {
        overflow: hidden;
    }
</style>